<ftr-page [pageStyle]="pageStyle">
  <div class="mfa-challenge">
    <h1>Authenticate</h1>
    <p class="mfa-challenge__text">Please enter your 6 digit multi-factor authentication code for For The Record.</p>
    <ftr-untyped-form
      name="configureName"
      [formGroup]="multifactorAuthenticationChallengeForm"
      [submission]="submissionSubject"
      (formSubmit)="submitForm()"
      submitLabel="Continue"
      submittingLabel="Validating"
      [submitDirection]="undefined"
      [formTemplate]="form"
      [formFooterTemplate]="formFooter"
      [disabled]="multifactorAuthenticationChallengeForm.invalid"
      [footerLeftAligned]="true"
    >
      <ng-template #form let-highlightErrors="highlightErrors">
        <ul class="form-list">
          <li>
            <ftr-form-input
              name="mfaCode"
              label="MFA Code"
              autocomplete="off"
              [notrack]="true"
              [highlightError]="highlightErrors"
              [spellcheck]="false"
              [ngFormControl]="asFormControl(multifactorAuthenticationChallengeForm.controls['mfaCode'])"
              [required]="true"
              [autofocus]="true"
              [submitAttempted]="submitAttempted"
            >
            </ftr-form-input>
          </li>
        </ul>
      </ng-template>
      <ng-template #formFooter>
        <a ftrButton [color]="buttonColor.Secondary" (click)="navigateHome()"> Back</a>
      </ng-template>
    </ftr-untyped-form>
    <p class="mfa-challenge__link">
      <a [routerLink]="recoverAccountPath" [queryParams]="{ returnUrl }"
        >I have lost my multi-factor authentication device</a
      >
    </p>
  </div>
</ftr-page>
