import { Component, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { ButtonColor, DialogService } from '@ftr/foundation'
import { Observable, from } from 'rxjs'

export interface CanHaveUnsavedChanges extends Component {
  /**
   * Whether this component has unsaved changes. If it does, a "Are you sure you want
   * to navigate away?" prompt will be shown to the user if they try to leave the page containing
   * the component.
   *
   * This guard can sometimes be called before the component has had a chance to fully
   * initialize (i.e. when called because of a navigation resulting from a forbidden error
   * being handled). Bear this in mind when writing the handler by safely accessing
   * elements of the component which may not be initialized.
   *
   * @returns {boolean}
   */
  hasUnsavedChanges(): boolean

  /**
   * A custom message to use when prompting the user to confirm that they wish to navigate
   * away from the page this component is on.
   *
   * @returns {string}
   */
  unsavedChangesConfirmationPrompt?(): string
}

@Injectable()
/**
 * Prompts the user to confirm that they want to navigate away from a page
 * with unsaved changes on it.
 *
 * See `AgentComponent` for an example of how this can be implemented.
 */
export class UnsavedChangesGuard {
  static readonly DEFAULT_CONFIRMATION_PROMPT =
    'You have unsaved changes. If you leave, your changes will be discarded. Do you wish to continue?'

  constructor(private dialogService: DialogService) {}

  /**
   * Called before navigation to determine whether the component currently being
   * displayed has any unsaved changes. If it does, the user is prompted to confirm
   * before navigating away. If it doesn't, navigation continues.
   *
   * When navigating to forbidden, unsaved changes are ignored and navigation continues
   * no matter what.
   */
  canDeactivate(
    component: Component | CanHaveUnsavedChanges,
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): Observable<boolean> {
    if (nextState.url === '/forbidden' || !isUnsavedChangesComponent(component) || !component.hasUnsavedChanges()) {
      return from([true])
    }

    const customPrompt =
      typeof component.unsavedChangesConfirmationPrompt === 'function' && component.unsavedChangesConfirmationPrompt()

    return this.dialogService.showDialog(
      customPrompt || UnsavedChangesGuard.DEFAULT_CONFIRMATION_PROMPT,
      'Unsaved Changes',
      'Stay on Page',
      'Discard Changes',
      'Unsaved Changes',
      'Warning',
      ButtonColor.Danger,
      false,
    )
  }
}

export function isUnsavedChangesComponent(component: Component): component is CanHaveUnsavedChanges {
  return component && typeof (component as CanHaveUnsavedChanges).hasUnsavedChanges === 'function'
}
