<div class="app-layout" [ngClass]="{ 'app-layout--white-background': routeData?.whiteBackground }">
  <ftr-header [logoData]="logoData"></ftr-header>

  <!-- Setting whiteBackground to true on a route will make the page background white
and add a shadow under the header to separate it from the page content -->
  @if (routeData?.whiteBackground) {
    <div class="app-layout__header-shadow"></div>
  }

  <main>
    <ng-content></ng-content>
  </main>

  @if (isFooterVisible) {
    <ftr-footer [hideFooterMargin]="routeData?.hideFooterMargin"></ftr-footer>
  }
</div>
