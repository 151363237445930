import { Inject, Injectable } from '@angular/core'
import {
  API_CONFIGURATION,
  ApiClient,
  ApiClientType,
  ApiErrorMapper,
  FullApiClientConfiguration,
  HttpClientRemoteData,
} from '@ftr/api-shared'
import { Region } from '@ftr/contracts/type/court'
import { CourtSystemRegionCache } from '@ftr/ui-court-system'
import { Observable, from, map } from 'rxjs'

/**
 * Create a client service for ftr-playback-api for a given controller path & courtSystemId
 */
@Injectable({
  providedIn: 'root',
})
export class PlaybackApiClientFactory {
  constructor(
    private readonly http: HttpClientRemoteData,
    @Inject(API_CONFIGURATION) private readonly config: FullApiClientConfiguration,
    private readonly apiErrorMapper: ApiErrorMapper,
    private readonly courtSystemRegionCache: CourtSystemRegionCache,
  ) {}

  buildForCourt(
    path: string,
    courtSystemId: string,
    httpService: HttpClientRemoteData = this.http,
  ): Observable<ApiClient> {
    const courtSystem$ = this.courtSystemRegionCache.getOrFetchCourtSystem(from([courtSystemId]))

    return courtSystem$.pipe(map(courtSystem => this.buildForRegion(path, courtSystem.region, httpService)))
  }

  /**
   * Lazy loaded and cached so the max number of api clients is equal to the number of playbacks we have
   *
   * @param path
   * @param region
   * @param httpService
   */
  buildForRegion(path: string, region: Region, httpService: HttpClientRemoteData = this.http): ApiClient {
    return new ApiClient(
      httpService,
      this.config,
      this.apiErrorMapper,
      { clientType: ApiClientType.playback, region },
      `/${path.replace(/^\//, '')}`,
    )
  }
}
