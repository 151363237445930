@if (hasPreviousConfirmationExpired()) {
  <ftr-notification [type]="notificationType.Warn">
    <p>Sorry, related annotations have been edited. Please reconfirm.</p>
  </ftr-notification>
}
<p>
  The following {{ this.hearingSections().length > 1 ? terms().hearing.plural : terms().hearing.singular }} will be
  deleted due to this update:
</p>
<div>
  @for (section of sortedHearingSections(); track section) {
    <div class="hearing-section">
      <div class="case-ref" [ngClass]="{ unset: !section.caseReference?.trim() }">
        {{ section.caseReference?.trim() || terms().caseNumber.singular }}
      </div>
      <div class="case-title" [ngClass]="{ unset: !section.title?.trim() }">
        {{ section.title?.trim() || terms().caseTitle.singular }}
      </div>
      <div class="times">
        {{ section.startTime | ftrDate: { format: dateFormat.TimeWithSeconds } }} -
        {{ section.endTime | ftrDate: { format: dateFormat.TimeWithSeconds } }}
      </div>
    </div>
  }
</div>
