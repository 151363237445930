import { NgTemplateOutlet } from '@angular/common'
import { Component, Input } from '@angular/core'
import { Data } from '@angular/router'
import { RemoteData } from '@ftr/foundation'
import { AppLayoutLegacyComponent } from '~app/core/app-layout-legacy/app-layout-legacy.component'
import { AppLayoutComponent } from '~app/core/app-layout/app-layout.component'

@Component({
  selector: 'ftr-app-display',
  templateUrl: './app-display.component.html',
  standalone: true,
  imports: [AppLayoutComponent, AppLayoutLegacyComponent, NgTemplateOutlet],
})
export class AppDisplayComponent {
  @Input() showNavigationRedesign: boolean
  @Input() logoData: RemoteData<string | undefined> = RemoteData.notAsked()
  @Input() routeData: Data | undefined

  @Input() isSearching: boolean
  @Input() isPlaybackPage: boolean
  @Input() isFooterVisible: boolean
}
