import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { Uuid } from '@ftr/contracts/type/shared'
import { DestroySubscribers, IconComponent } from '@ftr/foundation'
import { AppPaths, CourtSystemPaths } from '@ftr/routing-paths'
import { SearchManyState } from '@ftr/ui-search'
import { select } from '@ngxs/store'
import { SearchBarService } from '~app/core/header/search-container/search-bar/search-bar.service'
import { SearchBarComponent } from '~app/features/search/search-bar/search-bar.component'

import { SearchParams } from '~app/pages/search.params'

@Component({
  standalone: true,
  selector: 'ftr-top-bar-search',
  templateUrl: './top-bar-search.component.html',
  styleUrls: ['./top-bar-search.component.css'],
  imports: [SearchBarComponent, IconComponent],
})
export class TopBarSearchComponent extends DestroySubscribers {
  @Input() courtSystemId: Uuid
  @Input() isSearching: boolean

  protected searchContext = select(SearchManyState.searchContext)

  constructor(
    private readonly router: Router,
    private readonly searchBarService: SearchBarService,
  ) {
    super()
  }

  navigateToSearchResults(): void {
    this.searchBarService.setLastUrlBeforeSearching(this.router.url)
    const searchUrl = ['', AppPaths.CourtSystem, this.courtSystemId, CourtSystemPaths.Search]
    const searchContext = this.searchContext()
    this.router.navigate(searchUrl, {
      queryParams: {
        [SearchParams.SearchTerm]: searchContext?.searchTerms,
        [SearchParams.EntitiesToSearch]: searchContext?.selectedEntities,
        [SearchParams.SearchPageToken]: searchContext?.pageToken,
      },
    })
  }
}
