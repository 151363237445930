import { Injectable } from '@angular/core'
import { assertIsDefined } from '@ftr/contracts/shared/Assertions'
import { VocabularyTerms } from '@ftr/contracts/type/core'
import { DataStore, LocalTimeRange, Uuid } from '@ftr/contracts/type/shared'
import { RealTimePlaybackKey, getPlaybackId } from '@ftr/data-realtime-playback'
import { ToastService, tapData, tapFailure } from '@ftr/foundation'
import { LoggingService } from '@ftr/ui-observability'
import { FetchCourtRecording } from '@ftr/ui-playback'
import { VocabularyTermsService } from '@ftr/ui-vocab'
import { LocalTime } from '@js-joda/core'
import { Action, Selector, State, StateContext, StateOperator, createSelector } from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { memoize } from 'lodash-es'
import { firstValueFrom } from 'rxjs'
import { RealTimeSealingService } from '../../services/real-time-sealing'
import {
  RealTimeSttMarkerEditSource,
  RealTimeSttSealingMarker,
  getSealingMarkerId,
  getSealingMarkersFromSealedTimeRanges,
  isOngoing,
  parseStartEndTimes,
} from '../../types'
import {
  SealedTimeValidation,
  SealedTimeValidationReason,
  mapSealedTimeValidationReasonMessage,
  validateSealedSectionTimes,
} from '../../utils'
import {
  CreateSealedSegmentAction,
  DeleteSealedSegmentAction,
  SetSealingMarkersAction,
  UpdateSealedSegmentAction,
  UpdateSealingBoundariesAction,
  UpdateSealingLiveLocalTimeAction,
} from './real-time-sealing.actions'
import { RealTimeSealingModel, RealTimeSealingStateModel } from './real-time-sealing.model'

export const DEFAULT_NEW_SEALED_SEGMENT_LENGTH_SECONDS = 10

const defaultRealTimeSealedSegments = (): RealTimeSealingModel => ({
  markers: {},
  sealedSegments: [],
  sealingBoundaries: [],
  liveLocalTime: undefined,
})

export const defaultRealTimeSealingState: () => RealTimeSealingStateModel = () => ({
  realTimeSealing: {},
})

@State<RealTimeSealingStateModel>({
  name: 'realTimeSealingState',
  defaults: defaultRealTimeSealingState(),
})
@Injectable()
export class RealTimeSealingState {
  constructor(
    private readonly realTimeSealingService: RealTimeSealingService,
    private readonly toastService: ToastService,
    private readonly vocabularyTermsService: VocabularyTermsService,
    private readonly logger: LoggingService,
  ) {}

  @Selector()
  static allRealTimeSealingStates(state: RealTimeSealingStateModel): Record<Uuid, RealTimeSealingModel> {
    return state.realTimeSealing
  }

  static readonly realTimeSealing = memoize(
    (
      playbackKey: RealTimePlaybackKey,
    ): ((sourceStates: ReturnType<typeof RealTimeSealingState.allRealTimeSealingStates>) => RealTimeSealingModel) => {
      return createSelector(
        [RealTimeSealingState.allRealTimeSealingStates],
        (sourceStates: ReturnType<typeof RealTimeSealingState.allRealTimeSealingStates>) =>
          sourceStates[getPlaybackId(playbackKey)] ?? defaultRealTimeSealedSegments(),
      )
    },
    playbackKey => getPlaybackId(playbackKey),
  )

  static readonly sealingMarkers = memoize(
    (playbackKey: RealTimePlaybackKey): ((sourceStates: RealTimeSealingModel) => RealTimeSttSealingMarker[]) => {
      const selectedSourceState = RealTimeSealingState.realTimeSealing(playbackKey)
      return createSelector([selectedSourceState], (state: ReturnType<typeof selectedSourceState>) => {
        if (!state?.markers) {
          return []
        }
        return Object.values(state.markers)
      })
    },
    playbackKey => getPlaybackId(playbackKey),
  )

  @Action(SetSealingMarkersAction)
  setSealingMarkers(
    stateContext: StateContext<RealTimeSealingStateModel>,
    { playbackKey, recordingDate, timeframes }: SetSealingMarkersAction,
  ): void {
    const playbackId = getPlaybackId(playbackKey)
    const sealedSegments = timeframes.map(x => LocalTimeRange.fromTimeframe(x))
    const { liveLocalTime, savedMarker } = getOrInitializeRealTimeSealing(stateContext, playbackId)

    const markers = getSealingMarkersFromSealedTimeRanges(sealedSegments, recordingDate).reduce<
      Record<string, RealTimeSttSealingMarker>
    >((acc, marker) => {
      const isSavedMarker = savedMarker?.id === marker.id

      acc[marker.id] = {
        ...marker,
        status: isSavedMarker ? 'saved' : marker.status,
        source: isSavedMarker ? savedMarker?.source : marker.source,
        showAsOngoing: isOngoing(liveLocalTime, marker.sealedTimeRange),
      }

      return acc
    }, {})

    stateContext.setState(patchSealing(playbackId, { markers, sealedSegments, savedMarker: undefined }))
  }

  @Action(UpdateSealingBoundariesAction)
  updateSealingBoundaries(
    { setState }: StateContext<RealTimeSealingStateModel>,
    { playbackKey, sealingBoundaries }: UpdateSealingBoundariesAction,
  ): void {
    setState(patchSealing(getPlaybackId(playbackKey), { sealingBoundaries }))
  }

  @Action(UpdateSealingLiveLocalTimeAction)
  updateLiveLocalTime(
    stateContext: StateContext<RealTimeSealingStateModel>,
    { playbackKey, liveLocalTime }: UpdateSealingLiveLocalTimeAction,
  ): void {
    const playbackId = getPlaybackId(playbackKey)
    const { markers } = getOrInitializeRealTimeSealing(stateContext, playbackId)

    stateContext.setState(
      patchSealing(getPlaybackId(playbackKey), {
        liveLocalTime,
        markers: Object.fromEntries(
          Object.entries(markers ?? {}).map(([id, marker]) => [
            id,
            {
              ...marker,
              showAsOngoing: isOngoing(liveLocalTime, marker.sealedTimeRange),
            },
          ]),
        ),
      }),
    )
  }

  @Action(CreateSealedSegmentAction)
  async createSealedSegment(
    stateContext: StateContext<RealTimeSealingStateModel>,
    { playbackKey, startTime, courtSystemId }: CreateSealedSegmentAction,
  ): Promise<void> {
    const playbackId = getPlaybackId(playbackKey)
    const { sealedSegments, sealingBoundaries } = getOrInitializeRealTimeSealing(stateContext, playbackId)
    const terms = await firstValueFrom(this.vocabularyTermsService.observeTerms(courtSystemId))
    startTime = startTime.withNano(0)

    if (sealedSegments.find(x => x.contains(startTime))) {
      this.toastService.error(`Selected time is already ${terms.sealed.singular}.`)
      return
    }

    const enclosingBoundary = sealingBoundaries.find(x => x.contains(startTime))
    if (!enclosingBoundary) {
      this.toastService.error('Selected time is outside of session.')
      return
    }

    let endTime: LocalTime
    if (enclosingBoundary.end.isBefore(startTime.plusSeconds(DEFAULT_NEW_SEALED_SEGMENT_LENGTH_SECONDS))) {
      endTime = enclosingBoundary.end
    } else if (startTime.isAfter(LocalTime.MAX.minusSeconds(DEFAULT_NEW_SEALED_SEGMENT_LENGTH_SECONDS))) {
      endTime = LocalTime.MAX
    } else {
      endTime = startTime.plusSeconds(DEFAULT_NEW_SEALED_SEGMENT_LENGTH_SECONDS)
    }

    const recordingId = playbackKey.recordingId
    assertIsDefined(recordingId, 'Expected RealTimePlaybackKey to include recordingId')

    this.realTimeSealingService
      .addSealedSegment(courtSystemId, recordingId, new LocalTimeRange(startTime, endTime))
      .pipe(
        tapData(() => {
          stateContext.dispatch(new FetchCourtRecording(recordingId, courtSystemId, DataStore.Regional, true))
        }),
        tapFailure(() => {
          this.toastService.error(`Failed to add the ${terms.sealed.singular} section.`)
        }),
      )
      .subscribe()
  }

  @Action(DeleteSealedSegmentAction)
  async deleteSealedSegment(
    stateContext: StateContext<RealTimeSealingStateModel>,
    { playbackKey, courtSystemId, timeRange }: DeleteSealedSegmentAction,
  ): Promise<void> {
    const recordingId = playbackKey.recordingId
    assertIsDefined(recordingId, 'Expected RealTimePlaybackKey to include recordingId')

    const terms = await firstValueFrom(this.vocabularyTermsService.observeTerms(courtSystemId))

    this.realTimeSealingService
      .deleteSealedSegment(courtSystemId, recordingId, timeRange)
      .pipe(
        tapData(() => {
          stateContext.dispatch(new FetchCourtRecording(recordingId, courtSystemId, DataStore.Regional, true))
        }),
        tapFailure(() => {
          this.toastService.error(`Failed to delete the ${terms.sealed.singular} section.`)
        }),
      )
      .subscribe()
  }

  @Action(UpdateSealedSegmentAction)
  async updateSealedSegment(
    stateContext: StateContext<RealTimeSealingStateModel>,
    { playbackKey, markerId, startTime, endTime, courtSystemId, source }: UpdateSealedSegmentAction,
  ): Promise<void> {
    const playbackId = getPlaybackId(playbackKey)
    const terms = await firstValueFrom(this.vocabularyTermsService.observeTerms(courtSystemId))
    const { liveLocalTime, markers, sealingBoundaries } = getOrInitializeRealTimeSealing(stateContext, playbackId)
    const { dispatch, setState } = stateContext
    const marker = markers[markerId]
    const existing = marker?.sealedTimeRange

    if (!marker || !existing) {
      return
    }

    const parseResult = parseStartEndTimes<SealedTimeValidationReason>({ startTime, endTime })
    if ('parseError' in parseResult) {
      this.handleSealingErrors(playbackId, marker, parseResult.parseError, terms, setState, source)
      return
    }

    const updated = new LocalTimeRange(parseResult.startTime, parseResult.endTime)

    // Status reset if the updated sealing marker is the same, useful when undoing changes
    if (updated.equals(existing)) {
      setState(patchSealingMarker(playbackId, markerId, { status: 'loaded' }))
      return
    }

    const timeValidationError = validateSealedSectionTimes(
      parseResult.startTime,
      parseResult.endTime,
      sealingBoundaries,
      liveLocalTime,
    )

    if (timeValidationError) {
      this.handleSealingErrors(playbackId, marker, timeValidationError, terms, setState, source)
      return
    }

    setState(
      patchSealingMarker(playbackId, markerId, {
        status: 'saving',
        source,
      }),
    )

    const recordingId = playbackKey.recordingId
    assertIsDefined(recordingId, 'Expected RealTimePlaybackKey to include recordingId')

    this.realTimeSealingService
      .updateSealedSegment(courtSystemId, recordingId, existing, updated)
      .pipe(
        tapData(() => {
          // Updating savedMarker ith its future id just before reloading the recording
          setState(
            patchSealing(playbackId, {
              savedMarker: { id: getSealingMarkerId(marker.dividerType, updated), source },
            }),
          )

          dispatch(new FetchCourtRecording(recordingId, courtSystemId, DataStore.Regional, true))
        }),
        tapFailure(error => {
          this.logger.error({ message: 'Failed to update sealed section', error })
          setState(
            patchSealingMarker(playbackId, markerId, {
              status: { errorMessage: 'Failed to save.', showRetry: true, showReset: false },
              source,
            }),
          )
        }),
      )
      .subscribe()
  }

  private handleSealingErrors(
    playbackId: Uuid,
    marker: RealTimeSttSealingMarker,
    validationResult: SealedTimeValidation,
    terms: VocabularyTerms,
    setState: (val: StateOperator<RealTimeSealingStateModel> | RealTimeSealingStateModel) => void,
    source?: RealTimeSttMarkerEditSource,
  ): void {
    const updatedMarker = {
      ...marker,
      status: {
        errorMessage: mapSealedTimeValidationReasonMessage(validationResult, terms),
        fields: mapValidationReasonToFields(validationResult.reason),
        showRetry: false,
        showReset: true,
      },
      source,
    }

    setState(patchSealingMarker(playbackId, marker.id, updatedMarker))
  }
}

function patchSealing(id: Uuid, changes: Partial<RealTimeSealingModel>): StateOperator<RealTimeSealingStateModel> {
  return patch({
    realTimeSealing: patch({
      [id]: patch(changes),
    }),
  })
}

function patchSealingMarker(
  playbackId: Uuid,
  markerId: string,
  changes: Partial<RealTimeSttSealingMarker>,
): StateOperator<RealTimeSealingStateModel> {
  return patch({
    realTimeSealing: patch({
      [playbackId]: patch({
        markers: patch({
          [markerId]: patch(changes),
        }),
      }),
    }),
  })
}

function getOrInitializeRealTimeSealing(
  { getState, setState }: StateContext<RealTimeSealingStateModel>,
  playbackId: Uuid,
): RealTimeSealingModel {
  const originalState = getState()

  let realTimeSealing = originalState.realTimeSealing[playbackId]
  if (!realTimeSealing) {
    realTimeSealing = defaultRealTimeSealedSegments()
    setState(patchSealing(playbackId, realTimeSealing))
  }

  return realTimeSealing
}

function mapValidationReasonToFields(validationResult: SealedTimeValidationReason): (keyof RealTimeSttSealingMarker)[] {
  const affectsAll = ['both-out-of-bounds', 'different-sessions', 'start-must-be-before-end', 'both-are-not-times']
  const startIsInvalid = ['start-is-out-of-bounds', 'start-is-not-a-time', ...affectsAll].includes(validationResult)
  const endIsInvalid = ['end-is-out-of-bounds', 'future-end-time', 'end-is-not-a-time', ...affectsAll].includes(
    validationResult,
  )
  const fields: (keyof RealTimeSttSealingMarker)[] = []
  if (startIsInvalid) {
    fields.push('startTime')
  }
  if (endIsInvalid) {
    fields.push('endTime')
  }
  return fields
}
