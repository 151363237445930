import {
  HearingMarkerType,
  isHearingMarker,
  isSealingMarker,
  isSessionMarker,
  RealTimeSttTranscriptElement,
  SealingMarkerType,
  SessionMarkerType,
} from '../types'

type MarkerType = SessionMarkerType | SealingMarkerType | HearingMarkerType

// value a comparator should return when element a should be before element b
const before = -1
// value a comparator should return when element a should be after element b
const after = 1

/**
 * The rules below apply to markers starting or ending at the same time. This may happen
 * when session, hearing, sealing start or end at the same time, not just when they
 * represent the same time range, but also when they represent adjacent time ranges, for
 * example: hearing & session end at the same time as next session & sealing start.
 */
const markerComparisonRules = new Map<string, number>([
  comparisonRule('sessionStart', before, 'hearingStart'),
  comparisonRule('sessionStart', before, 'sealingStart'),
  comparisonRule('sessionEnd', after, 'hearingEnd'),
  comparisonRule('sessionEnd', after, 'sealingEnd'),
  comparisonRule('sessionEnd', before, 'hearingStart'),
  comparisonRule('sessionEnd', before, 'sealingStart'),
  comparisonRule('hearingStart', before, 'sealingStart'),
  comparisonRule('hearingStart', after, 'sealingEnd'),
  comparisonRule('hearingEnd', after, 'sealingEnd'),
  comparisonRule('hearingEnd', before, 'sealingStart'),
  comparisonRule('hearingEnd', before, 'sessionStart'),
  comparisonRule('sealingEnd', before, 'sessionStart'),
  comparisonRule('hearingEnd', before, 'hearingStart'),
  comparisonRule('sealingEnd', before, 'sealingStart'),
  comparisonRule('sessionEnd', before, 'sessionStart'),
])

/*
When a session, hearing and a sealed segment start or end at the same time, the session should enclose the hearing,
and the hearing should enclose sealed segments
[ session start | hearing start | sealing start | sealing end | hearing end | session end ].
There cannot be any sealed content outside of sessions or hearings

Note: if a remark happens to exist between two of these markers at exactly the same second (unlikely) it may break
the sorting – this could be fixed by sorting these independently and stitching them back together but it’s
so rare/expensive it’s not worth it. At the moment we re-sort transcript elements each time we add remarks or
markers, which steps around this issue.
 */
export function compareTranscriptElements(
  a: RealTimeSttTranscriptElement,
  b: RealTimeSttTranscriptElement,
  time: keyof Pick<RealTimeSttTranscriptElement, 'startTime' | 'endTime'> = 'startTime',
): number {
  if (!a[time].isEqual(b[time])) {
    return a[time].compareTo(b[time])
  }

  return getCustomMarkersComparison(a, b)
}

export function getCustomMarkersComparison(a: RealTimeSttTranscriptElement, b: RealTimeSttTranscriptElement): number {
  const aDividerType = getDividerType(a)
  const bDividerType = getDividerType(b)

  if (aDividerType && bDividerType) {
    const comparisonLookup = markerComparisonRules.get(toMarkerKey(aDividerType, bDividerType))
    if (comparisonLookup) {
      return comparisonLookup
    }
    const reverseComparisonLookup = markerComparisonRules.get(toMarkerKey(bDividerType, aDividerType))
    if (reverseComparisonLookup) {
      return -1 * reverseComparisonLookup
    }
  }
  return 0
}

function comparisonRule(markerTypeA: MarkerType, beforeOrAfter: number, markerTypeB: MarkerType): [string, number] {
  return [toMarkerKey(markerTypeA, markerTypeB), beforeOrAfter]
}

function toMarkerKey(markerTypeA: MarkerType, markerTypeB: MarkerType): string {
  return `${markerTypeA}-${markerTypeB}`
}

function getDividerType(element: RealTimeSttTranscriptElement): MarkerType | undefined {
  if (isSessionMarker(element)) {
    return element.dividerType
  }
  if (isSealingMarker(element)) {
    return element.dividerType
  }
  if (isHearingMarker(element)) {
    return element.dividerType
  }
  return undefined
}
