import { Injectable } from '@angular/core'
import { SearchEntityType, SearchManyResponse } from '@ftr/contracts/api/search'
import { RemoteData } from '@ftr/foundation'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { isEqual } from 'lodash-es'
import { Observable, filter, map, of, tap } from 'rxjs'
import { SearchService } from '../../services/search/search.service'
import { SearchFocusState } from '../shared'
import {
  ClearSearchManyResultsAction,
  FetchSearchEntityTypeToNumberOfMatchesAction,
  FetchSearchManyResultsAction,
  SetSearchManyFocusStateAction,
} from './search-many.actions'
import { SearchManyContext, SearchManyStateModel } from './search-many.model'

export function defaultSearchManyState(): SearchManyStateModel {
  return {
    context: null,
    searchResponse: RemoteData.notAsked(),
    searchEntityTypeToNumberOfMatches: new Map<SearchEntityType, number>(),
    searchFocusState: SearchFocusState.NONE,
  }
}

@State<SearchManyStateModel>({
  name: 'searchManyState',
  defaults: defaultSearchManyState(),
})
@Injectable()
export class SearchManyState {
  constructor(private readonly searchService: SearchService) {}

  @Selector()
  static state(state: SearchManyStateModel): SearchManyStateModel {
    return state
  }

  @Selector()
  static searchResponse(state: SearchManyStateModel): RemoteData<SearchManyResponse> {
    return state.searchResponse
  }

  @Selector()
  static searchEntityTypeToNumberOfMatches(state: SearchManyStateModel): ReadonlyMap<SearchEntityType, number> {
    return state.searchEntityTypeToNumberOfMatches
  }

  @Selector()
  static searchContext(state: SearchManyStateModel): SearchManyContext | null {
    return state.context
  }

  @Selector()
  static searchFocusState(state: SearchManyStateModel): SearchFocusState {
    return state.searchFocusState
  }

  @Action(SetSearchManyFocusStateAction)
  setSearchFocusState(
    { patchState }: StateContext<SearchManyStateModel>,
    { searchFocusState }: SetSearchManyFocusStateAction,
  ): void {
    patchState({ searchFocusState })
  }

  @Action(FetchSearchManyResultsAction, { cancelUncompleted: true })
  fetchSearchManyResults(
    { patchState, getState }: StateContext<SearchManyStateModel>,
    { searchTerms, searchedEntities, selectedEntities, courtSystemId, pageToken }: FetchSearchManyResultsAction,
  ): Observable<void> {
    const context: SearchManyContext = {
      courtSystemId,
      searchedEntities,
      selectedEntities,
      searchTerms,
      pageToken,
    }

    const oldState = getState()
    // If our context is the same and we have a successful result, then no work needs to be done
    if (isEqual(context, oldState.context) && oldState.searchResponse.isSuccess()) {
      return of()
    }

    patchState({
      context,
    })
    return this.searchService.doSearchMany(searchTerms, courtSystemId, searchedEntities, pageToken).pipe(
      filter(() => context === getState().context),
      tap((searchResponse: RemoteData<SearchManyResponse>) => {
        patchState({
          searchResponse,
        })
      }),
      map(() => undefined),
    )
  }

  @Action(FetchSearchEntityTypeToNumberOfMatchesAction, { cancelUncompleted: true })
  fetchSearchResultsMetadata(
    { patchState }: StateContext<SearchManyStateModel>,
    { searchTerm, entities, courtSystemId }: FetchSearchEntityTypeToNumberOfMatchesAction,
  ): Observable<void> {
    patchState({ searchEntityTypeToNumberOfMatches: new Map<SearchEntityType, number>() })
    if (!searchTerm || entities.length === 0) {
      return of()
    }

    return this.searchService.fetchSearchResultsTotalMatches(searchTerm, courtSystemId, entities).pipe(
      tap(searchEntityTypeToNumberOfMatches => {
        patchState({ searchEntityTypeToNumberOfMatches })
      }),
      map(() => undefined),
    )
  }

  @Action(ClearSearchManyResultsAction, { cancelUncompleted: true })
  clearSearchResults({ patchState }: StateContext<SearchManyStateModel>): void {
    patchState(defaultSearchManyState())
  }
}
