import { Component, computed, EventEmitter, input, Output } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Uuid } from '@ftr/contracts/type/shared'
import {
  BreadcrumbModule,
  BreadcrumbService,
  ButtonColor,
  ButtonComponent,
  ButtonDisplayType,
  IconComponent,
} from '@ftr/foundation'
import { AppPaths, CourtSystemPaths } from '@ftr/routing-paths'
import { filter, map, tap } from 'rxjs'
import { AccountDropdownComponent } from '~app/core/account-dropdown/account-dropdown.component'
import { TopBarSearchComponent } from '~app/features/search/top-bar-search.component'

@Component({
  selector: 'ftr-top-bar-display',
  templateUrl: './top-bar-display.component.html',
  styleUrls: ['./top-bar-display.component.css'],
  standalone: true,
  imports: [AccountDropdownComponent, IconComponent, BreadcrumbModule, TopBarSearchComponent, ButtonComponent],
})
export class TopBarDisplayComponent {
  @Output() sideBarToggled = new EventEmitter<void>()

  currentCourtSystemId = input<Uuid | undefined>()
  showingBreadcrumbs = false
  buttonDisplayType = ButtonDisplayType
  buttonColor: ButtonColor = ButtonColor.Grey

  protected routeUrlSignal = computed(() => this.router.url)

  ngOnInit(): void {
    this.showingBreadcrumbs = this.breadcrumbService.isShowingBreadcrumbs(this.activatedRoute)
  }

  protected newRouteUrlSignal = toSignal(
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => (event instanceof NavigationEnd ? event.url : '')),
      tap(() => (this.showingBreadcrumbs = this.breadcrumbService.isShowingBreadcrumbs(this.activatedRoute))),
    ),
    { initialValue: '' },
  )

  isSearchingSignal = computed(() => {
    const routeUrl = this.routeUrlSignal()
    const newRouteUrl = this.newRouteUrlSignal()
    const searchUrl = [`/${AppPaths.CourtSystem}`, this.currentCourtSystemId(), CourtSystemPaths.Search].join('/')
    // True when we navigate to the search page or start there
    return newRouteUrl.startsWith(searchUrl) || (routeUrl.startsWith(searchUrl) && !newRouteUrl)
  })

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly breadcrumbService: BreadcrumbService,
  ) {}

  toggleSideBar(): void {
    this.sideBarToggled.emit()
  }
}
