<ftr-page [pageStyle]="pageStyle">
  <div class="recover-account">
    <h1>Recover Account</h1>
    <p>Please enter one of your recovery codes that was provided when first enabling your MFA.</p>
    <p>
      <ftr-icon icon="Info" color="Primary" size="Large" align="Bottom" gapRight="Small"></ftr-icon>
      For more information, please refer to our
      <ftr-help-link cls="elevio__recovery-codes" label="Recovery Codes"></ftr-help-link> article.
    </p>
    <ftr-untyped-form
      name="recoverAccount"
      [submission]="submission"
      [formGroup]="recoverAccountForm"
      (formSubmit)="submitForm()"
      submitLabel="Continue"
      submittingLabel="Validating"
      [formTemplate]="form"
      [submitFullWidth]="false"
      [submitDirection]="undefined"
      [formFooterTemplate]="formFooter"
      [disabled]="recoverAccountForm.invalid"
      [footerLeftAligned]="true"
    >
      <ng-template #form let-highlightErrors="highlightErrors">
        <ul class="form-list">
          <li>
            <ftr-form-input
              name="recoveryCode"
              label="Recovery Code"
              autocomplete="off"
              [ngFormControl]="asFormControl(recoverAccountForm.controls['recoveryCode'])"
              [required]="true"
              [highlightError]="highlightErrors"
              [notrack]="true"
              [autofocus]="true"
              [submitAttempted]="submitAttempted"
            >
            </ftr-form-input>
          </li>
        </ul>
      </ng-template>
      <ng-template #formFooter>
        <a
          [routerLink]="[mfaChallengePath]"
          ftrButton
          [queryParams]="{ returnUrl }"
          [color]="buttonColor.Secondary"
          class="cancel-button"
          >Back</a
        >
      </ng-template>
    </ftr-untyped-form>
  </div>
</ftr-page>
