import { Injectable } from '@angular/core'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { DEFAULT_VOCABULARY_TERMS } from '@ftr/contracts/type/core/VocabularyTerms'
import { Uuid } from '@ftr/contracts/type/shared'
import { isNotNullOrUndefined } from '@ftr/foundation'
import { StaticMenuItem } from '@ftr/menus'
import { CourtSystemNavItems, GlobalNavItems, NavItemsProvider, TranscriberNavItems } from '@ftr/ui-core'
import { UserState } from '@ftr/ui-user'
import { VocabularyState } from '@ftr/ui-vocab'
import { Store } from '@ngxs/store'
import { Observable, combineLatest, filter, map, of, switchMap } from 'rxjs'
import { GlobalAdminNavItems } from './global-admin-nav-items'

/**
 * The sidenav on mobile
 */
@Injectable({
  providedIn: 'root',
})
export class MainNavMenuItemsProvider implements NavItemsProvider {
  constructor(
    private readonly globalNavItems: GlobalNavItems,
    private readonly courtSystemNavItems: CourtSystemNavItems,
    private readonly transcriberNavItems: TranscriberNavItems,
    private readonly globalAdminNavItems: GlobalAdminNavItems,
    private readonly store: Store,
  ) {}

  /**
   * The logic used to display the side-nav drop down menu is as per design.
   */
  provide(): Observable<StaticMenuItem[]> {
    let currentCourtSystemId: Uuid
    return this.store.select(UserState.currentCourtSystem).pipe(
      switchMap(courtSystem => {
        if (courtSystem && courtSystem.id) {
          currentCourtSystemId = courtSystem.id
        }
        return combineLatest({
          hasProcessAudioOrdersPermission: this.store
            .select(UserState.hasPermissionInCourtSystem)
            .pipe(map(fn => fn(UserGroupPermissionId.ProcessAudioOrders))),
          hasProcessRealTimeOrdersPermission: this.store
            .select(UserState.hasPermissionInCourtSystem)
            .pipe(map(fn => fn(UserGroupPermissionId.ProcessRealTimeOrders))),
          hasProcessTranscriptOrdersPermission: this.store
            .select(UserState.hasPermissionInCourtSystem)
            .pipe(map(fn => fn(UserGroupPermissionId.ProcessTranscriptOrders))),
          hasPlaybackRecordingsPermission: this.store
            .select(UserState.hasPermissionInCourtSystem)
            .pipe(map(fn => fn(UserGroupPermissionId.PlaybackRecordings))),
          hasPlaybackHearingsPermission: this.store
            .select(UserState.hasPermissionInCourtSystem)
            .pipe(map(fn => fn(UserGroupPermissionId.PlaybackHearings))),
          hasTranscribeRecordingsPermission: this.store
            .select(UserState.hasPermissionInAnyCourtSystem)
            .pipe(map(fn => fn(UserGroupPermissionId.TranscribeRecordings))),
          isGlobalAdmin: this.store.select(UserState.isGlobalAdmin),
          vocabularyTerms: currentCourtSystemId
            ? this.store.select(VocabularyState.termsByCourtSystemId).pipe(
                map(fn => fn(currentCourtSystemId)),
                filter(isNotNullOrUndefined),
              )
            : of(DEFAULT_VOCABULARY_TERMS),
        }).pipe(
          map(
            ({
              hasProcessAudioOrdersPermission,
              hasProcessRealTimeOrdersPermission,
              hasProcessTranscriptOrdersPermission,
              hasPlaybackRecordingsPermission,
              hasPlaybackHearingsPermission,
              hasTranscribeRecordingsPermission,
              isGlobalAdmin,
              vocabularyTerms,
            }) => {
              const navItems: StaticMenuItem[] = []
              const hasProcessingPermissions =
                hasProcessAudioOrdersPermission ||
                hasProcessRealTimeOrdersPermission ||
                hasProcessTranscriptOrdersPermission ||
                hasPlaybackRecordingsPermission ||
                hasTranscribeRecordingsPermission

              if (hasProcessTranscriptOrdersPermission) {
                navItems.push(this.courtSystemNavItems.transcriptOrders(currentCourtSystemId))
              }

              if (hasProcessAudioOrdersPermission) {
                navItems.push(this.courtSystemNavItems.audioOrders(currentCourtSystemId))
              }

              if (hasProcessRealTimeOrdersPermission) {
                navItems.push(this.courtSystemNavItems.realTimeOrders(currentCourtSystemId))
              }

              if (hasPlaybackRecordingsPermission) {
                navItems.push(this.courtSystemNavItems.recordings(currentCourtSystemId))
              }

              if (hasPlaybackHearingsPermission) {
                navItems.push(this.courtSystemNavItems.cases(currentCourtSystemId, vocabularyTerms))
              }

              if (hasTranscribeRecordingsPermission) {
                navItems.push(this.transcriberNavItems.jobsList())
              }

              navItems.push(this.globalNavItems.myOrders())

              if (!(isGlobalAdmin || hasProcessingPermissions)) {
                navItems.push(this.globalNavItems.sharedRecordings(), this.globalNavItems.myUploads())
              }

              if (isGlobalAdmin) {
                navItems.push(this.globalAdminNavItems.globalAdmin())
              }

              return navItems
            },
          ),
        )
      }),
    )
  }
}
