import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { DateTimeFormatter } from '@js-joda/core'
import { Locale } from '@js-joda/locale_en-us'
import {
  MOMENT_DATE_FORMAT_LONG,
  MOMENT_DATE_FORMAT_NORMAL,
  MOMENT_DATE_FORMAT_SHORT,
  MOMENT_DATE_FORMAT_WITH_DAY,
  MOMENT_DATE_LONG_NO_COMMA,
  MOMENT_DAY_FORMAT_LONG,
  MOMENT_DAY_FORMAT_NUMERIC,
  MOMENT_DAY_FORMAT_SHORT,
  MOMENT_MONTH_FORMAT_NUMERIC,
  MOMENT_MONTH_FORMAT_SHORT,
  MOMENT_YEAR_FORMAT_LONG,
} from './DateFormats'
import { TIME_FORMAT } from './TimeFormat'
import { MOMENT_DATETIME_CONVERSION_FORMAT, MOMENT_DATETIME_ZONED_CONVERSION_FORMAT } from './toJoda'

/**
 * Date formats which can be used with DateComponent and DatePipe.
 *
 * @see DateComponent
 * @see DatePipe
 */
export enum DateFormat {
  /**
   * @example "1:23 AM"
   */
  Time = 'time',
  /**
   * @example "01:23 AM"
   */
  TimeLeadingZero = 'timeLeadingZero',
  /**
   * @example "10:23:00 AM"
   */
  TimeWithSeconds = 'timeWithSeconds',
  /**
   * @example "10:23:00 AM"
   */
  TimeWithMilliseconds = 'timeWithMilliseconds',
  /**
   * @example "23:49:10"
   */
  TimeTwentyFourHourWithSeconds = 'timeTwentyFourHourWithSeconds',
  /**
   * @example "08:49"
   */
  TimeTwentyFourHourPadded = 'timeTwentyFourHourPadded',
  /**
   * @example "Oct 04, 2019"
   */
  Date = 'date',
  /**
   * @example "04 Oct 2019"
   */
  DateDayFirst = 'dateDayFirst',
  /**
   * @example "04 October, 2019"
   */
  DateLong = 'dateLong',
  /**
   * @example "04 October 2019"
   */
  DateLongNoComma = 'dateLongNoComma',
  /**
   * @example "04 October 2019, Thursday"
   */
  DateWithDay = 'dateWithDay',
  /**
   * @example "04 October, 2019 10:23 AM"
   */
  DateAndTime = 'dateAndTime',
  /**
   * @example "Oct 04, 2019 10:23 AM"
   */
  DateAndTimeShorter = 'dateAndTimeShorter',
  /**
   * @example "10/04/2019 10:23 AM"
   */
  DateAndTimeShort = 'dateAndTimeShort',
  /**
   * @example "10/04/2019 10:23 AM"
   */
  DateShort = 'dateShort',
  /**
   * @example "Friday"
   */
  DayOfWeek = 'dayOfWeek',

  /**
   * @example 2019-07-09T12:00:00.000
   */
  IsoLocalDateTime = 'isoLocal',

  /**
   * @example 2019-07-09T12:00:00.000Z
   */
  IsoZonedDateTime = 'iso',
  /**
   * @example Apr 05, 2019
   */
  MonthDayYearShort = 'monthDayYearShort',
  /**
   * @example Tue
   */
  ShortDayOfWeek = 'shortDayOfWeek',
  /**
   * @example 2019-12-04
   */
  UniversalDate = 'universalDate',
}

export const ALL_DATE_FORMATS = Object.values(DateFormat) as DateFormat[]

/**
 * Given a date format, returns a moment formatter string which is can be used for
 * converting a date into the desired format.
 */
// eslint-disable-next-line complexity
export function momentFormatter(desiredFormat: DateFormat): string {
  switch (desiredFormat) {
    case DateFormat.Time:
      return TIME_FORMAT.TWELVE_HOUR.MINUTE_PRECISION.DEFAULT
    case DateFormat.TimeLeadingZero:
      return TIME_FORMAT.TWELVE_HOUR.MINUTE_PRECISION.LEADING_ZERO
    case DateFormat.TimeWithSeconds:
      return TIME_FORMAT.TWELVE_HOUR.SECOND_PRECISION.DEFAULT
    case DateFormat.TimeWithMilliseconds:
      return TIME_FORMAT.TWELVE_HOUR.MILLISECOND_PRECISION.DEFAULT
    case DateFormat.TimeTwentyFourHourWithSeconds:
      return TIME_FORMAT.TWENTY_FOUR_HOUR.SECOND_PRECISION.DEFAULT
    case DateFormat.TimeTwentyFourHourPadded:
      return TIME_FORMAT.TWENTY_FOUR_HOUR.MINUTE_PRECISION.PADDED
    case DateFormat.Date:
      return MOMENT_DATE_FORMAT_NORMAL
    case DateFormat.DateDayFirst:
      return `${MOMENT_DAY_FORMAT_NUMERIC} ${MOMENT_MONTH_FORMAT_SHORT} ${MOMENT_YEAR_FORMAT_LONG}`
    case DateFormat.DateLong:
      return MOMENT_DATE_FORMAT_LONG
    case DateFormat.DateLongNoComma:
      return MOMENT_DATE_LONG_NO_COMMA
    case DateFormat.DateWithDay:
      return MOMENT_DATE_FORMAT_WITH_DAY
    case DateFormat.DateAndTime:
      return `${MOMENT_DATE_FORMAT_LONG} ${TIME_FORMAT.TWELVE_HOUR.MINUTE_PRECISION.DEFAULT}`
    case DateFormat.DateAndTimeShorter:
      return `${MOMENT_DATE_FORMAT_NORMAL} ${TIME_FORMAT.TWELVE_HOUR.MINUTE_PRECISION.DEFAULT}`
    case DateFormat.DateAndTimeShort:
      return `${MOMENT_DATE_FORMAT_SHORT} ${TIME_FORMAT.TWELVE_HOUR.MINUTE_PRECISION.DEFAULT}`
    case DateFormat.DateShort:
      return `${MOMENT_DATE_FORMAT_SHORT}`
    case DateFormat.DayOfWeek:
      return MOMENT_DAY_FORMAT_LONG
    case DateFormat.IsoZonedDateTime:
      return MOMENT_DATETIME_ZONED_CONVERSION_FORMAT
    case DateFormat.IsoLocalDateTime:
      return MOMENT_DATETIME_CONVERSION_FORMAT
    case DateFormat.ShortDayOfWeek:
      return MOMENT_DAY_FORMAT_SHORT
    case DateFormat.MonthDayYearShort:
      return `${MOMENT_MONTH_FORMAT_SHORT} ${MOMENT_DAY_FORMAT_NUMERIC}, ${MOMENT_YEAR_FORMAT_LONG}`
    case DateFormat.UniversalDate:
      return `${MOMENT_YEAR_FORMAT_LONG}-${MOMENT_MONTH_FORMAT_NUMERIC}-${MOMENT_DAY_FORMAT_NUMERIC}`
    default:
      return assertUnreachable(desiredFormat)
  }
}

/**
 * Given a date format, returns a joda formatter string which is can be used for
 * converting a date into the desired format. This has the locale as it's required
 * for formats like am/pm, day of week etc.
 */
export function jodaFormatPatternWithLocale(desiredFormat: DateFormat): DateTimeFormatter {
  return jodaFormatPattern(desiredFormat).withLocale(Locale.US)
}

/**
 * Given a date format, returns a joda formatter string which is can be used for
 * converting a date into the desired format.
 */
// eslint-disable-next-line complexity
function jodaFormatPattern(desiredFormat: DateFormat): DateTimeFormatter {
  switch (desiredFormat) {
    case DateFormat.Time:
      return DateTimeFormatter.ofPattern('h:mm a')
    case DateFormat.TimeLeadingZero:
      return DateTimeFormatter.ofPattern('hh:mm a')
    case DateFormat.TimeWithSeconds:
      return DateTimeFormatter.ofPattern('h:mm:ss a')
    case DateFormat.TimeWithMilliseconds:
      return DateTimeFormatter.ofPattern('h:mm:ss:SSS a')
    case DateFormat.TimeTwentyFourHourWithSeconds:
      return DateTimeFormatter.ofPattern('H:mm:ss')
    case DateFormat.TimeTwentyFourHourPadded:
      return DateTimeFormatter.ofPattern('HH:mm')
    case DateFormat.Date:
      return DateTimeFormatter.ofPattern('MMM dd, y')
    case DateFormat.DateDayFirst:
      return DateTimeFormatter.ofPattern('dd MMM y')
    case DateFormat.DateLong:
      return DateTimeFormatter.ofPattern('dd MMMM, y')
    case DateFormat.DateLongNoComma:
      return DateTimeFormatter.ofPattern('dd MMMM y')
    case DateFormat.DateWithDay:
      return DateTimeFormatter.ofPattern('dd MMMM y, eeee')
    case DateFormat.DateAndTime:
      return DateTimeFormatter.ofPattern('dd MMMM, y h:mm a')
    case DateFormat.DateAndTimeShorter:
      return DateTimeFormatter.ofPattern('MMM dd, y h:mm a')
    case DateFormat.DateAndTimeShort:
      return DateTimeFormatter.ofPattern('MM/dd/y h:mm a')
    case DateFormat.DateShort:
      return DateTimeFormatter.ofPattern('MM/dd/y')
    case DateFormat.DayOfWeek:
      return DateTimeFormatter.ofPattern('eeee')
    case DateFormat.ShortDayOfWeek:
      return DateTimeFormatter.ofPattern('E')
    case DateFormat.IsoLocalDateTime:
      return DateTimeFormatter.ofPattern("y-MM-dd'T'HH:mm:ss.SSS")
    case DateFormat.IsoZonedDateTime:
      return DateTimeFormatter.ofPattern("y-MM-dd'T'HH:mm:ss.SSSXXX")
    case DateFormat.MonthDayYearShort:
      return DateTimeFormatter.ofPattern('MMM dd, y')
    case DateFormat.UniversalDate:
      return DateTimeFormatter.ofPattern('yyyy-MM-dd')
    default:
      return assertUnreachable(desiredFormat)
  }
}
