import { InjectionToken } from '@angular/core'
import { MultiRegionApiConfiguration } from './multi-region-api-configuration'
import { ServerConfiguration } from './server-configuration'
import { SocketConfiguration } from './socket-configuration'

export const API_CONFIGURATION = new InjectionToken<FullApiClientConfiguration>('API_CONFIGURATION')

export interface FullApiClientConfiguration {
  annotationsApi: MultiRegionApiConfiguration
  regionalApi: MultiRegionApiConfiguration
  playbackApi: MultiRegionApiConfiguration
  restApi: MultiRegionApiConfiguration
  courtUploaderServer: MultiRegionApiConfiguration
  digitalJusticeApi: MultiRegionApiConfiguration
  digitalJusticeWeb: MultiRegionApiConfiguration
  auditApi: MultiRegionApiConfiguration
  server: ServerConfiguration
  socket: SocketConfiguration
  globalAdminUrl: string
}
