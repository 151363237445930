import { Uuid } from '@ftr/contracts/type/shared'
import { Subscription } from 'rxjs'

export enum UploadStates {
  NotSent = 'NOTSENT',
  Initializing = 'INITIALIZING',
  Uploading = 'UPLOADING',
  Finalizing = 'FINALIZING',
  Failure = 'FAILURE',
  Success = 'SUCCESS',
  Canceled = 'CANCELED',
  Scanning = 'SCANNING',
  Deleting = 'DELETING',
}

/**
 * Used as a replacement for the browser File object when dealing with an already uploaded file
 */
export interface UploadedFile {
  name: string
  /**
   * Size of the file in bytes
   */
  size: number
}

export interface FileUpload<T = {}, R = undefined> {
  id: Uuid
  file: File | UploadedFile
  status: UploadStates
  error?: string
  fileId?: Uuid
  progress?: number
  checksum?: string
  url?: string
  upload?: Subscription
  label?: string
  props: T
  response?: R
}

export interface FileUploadWithChecksum<T = {}, R = undefined> extends FileUpload<T, R> {
  checksum: string
}

export interface InitializedFileUpload<T = {}, R = undefined> extends FileUploadWithChecksum<T, R> {
  url: string
  fileId: Uuid
  progress: number
}

export interface UploadingFileUpload<T = {}, R = undefined> extends InitializedFileUpload<T, R> {
  status: UploadStates.Uploading
}

export interface FinalizingFileUpload<T = {}, R = undefined> extends InitializedFileUpload<T, R> {
  status: UploadStates.Finalizing
}

export interface ScanningFileUpload<T = {}, R = undefined> extends InitializedFileUpload<T, R> {
  status: UploadStates.Scanning
}

export interface SuccessfulFileUpload<T = {}, R = undefined> extends InitializedFileUpload<T, R> {
  status: UploadStates.Success
}

export interface FailedFileUpload<T = {}, R = undefined> extends InitializedFileUpload<T, R> {
  status: UploadStates.Failure
}

export type UploadingOrFinalizingFileUpload<T, R> = UploadingFileUpload<T, R> | FinalizingFileUpload<T, R>

export type FileUploadStatusEvent<T, R> =
  | InitializedFileUpload<T, R>
  | UploadingFileUpload<T, R>
  | FinalizingFileUpload<T, R>
  | ScanningFileUpload<T, R>
  | SuccessfulFileUpload<T, R>
  | FailedFileUpload<T, R>

export enum FileUploadError {
  ScanFailedToStart = 'Virus scan failed to start',
  ScanFailed = 'Virus scanning failed',
  ScanTimedOut = 'Virus scanning timed out',
  FileTypeNotAccepted = 'File type is not accepted',
  FileUploadFailed = 'Failed to upload file',
  VirusDetected = 'Virus detected, remove or replace file before continuing',
}

export interface UploadImagePublicUrlResponse {
  publicUrl: string
}
