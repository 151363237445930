import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { DateFormat, DefaultTimeStringParseMethod, TimeStringParseMethod } from '@ftr/foundation'
import { Observable } from 'rxjs'
import { ValidationErrorHintDirective } from '../../directives'
import { INVALID_TIME_MESSAGE, TimeInputComponent } from '../time-input'
import { TimeRangeControl } from './time-range-control'

export enum TimePrecision {
  Seconds = 'seconds',
  Minutes = 'minutes',
}

const TIME_RANGE_PRECISION_CONFIG = {
  [TimePrecision.Minutes]: { placeholder: 'HH:MM AM/PM', format: DateFormat.Time },
  [TimePrecision.Seconds]: { placeholder: 'HH:MM:SS AM/PM', format: DateFormat.TimeWithSeconds },
}

@Component({
  standalone: true,
  selector: 'ftr-time-range',
  templateUrl: './time-range.component.html',
  styleUrls: ['./time-range.component.css'],
  imports: [TimeInputComponent, ValidationErrorHintDirective],
  encapsulation: ViewEncapsulation.None,
})
export class TimeRangeComponent implements OnInit {
  @Input() control: TimeRangeControl
  @Input() highlightError: Observable<boolean>
  @Input() submitAttempted: boolean
  @Input() startLabel: string = 'From'
  @Input() endLabel: string = 'To'
  @Input() precision: TimePrecision = TimePrecision.Minutes
  @Input() verticalAlign = false
  @Input() placeholder?: string
  @Input() timeFormat?: DateFormat
  @Input() invalidTimeMessage: string = INVALID_TIME_MESSAGE
  @Input() timeStringParseMethods: TimeStringParseMethod[] = [DefaultTimeStringParseMethod]
  @Output() inputChanged = new EventEmitter<void>()

  internalPlaceholder: string
  internalTimeFormat: DateFormat

  ngOnInit(): void {
    this.internalPlaceholder = this.placeholder ?? TIME_RANGE_PRECISION_CONFIG[this.precision].placeholder
    this.internalTimeFormat = this.timeFormat ?? timeFormatForPrecision(this.precision)
  }

  propagateInputChangedEvent(): void {
    this.inputChanged.emit()
  }
}

export const timeFormatForPrecision = (timePrecision: TimePrecision): DateFormat =>
  TIME_RANGE_PRECISION_CONFIG[timePrecision].format
