<div #tooltip class="tooltip" [ngClass]="['tooltip--' + position + '-' + alignment]" data-testid="tooltip-container">
  @if (template) {
    <div class="tooltip__complex">
      <ng-container *ngTemplateOutlet="template; context: templateContext ?? null"></ng-container>
    </div>
  }
  @if (!template) {
    <span class="tooltip__text">
      {{ message }}
    </span>
  }
  <span class="tooltip__chevron" [style]="indicatorPositionStyle"></span>
</div>
