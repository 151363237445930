import { Injectable } from '@angular/core'
import { EntitySearchResponse } from '@ftr/contracts/api/search'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, RemoteData } from '@ftr/foundation'
import { SttContext, matchSttContext } from '@ftr/stt-search'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'
import { SearchService } from '../../services/search/search.service'
import {
  GetSearchResultsAction,
  SetPermittedToSearchThisAudioSegment,
  SetSearchTermAction,
  SetSttContextAction,
} from './this-recording-search.actions'
import { LastSearchContext, ThisRecordingSearchStateModel } from './this-recording-search.model'

export function defaultThisRecordingSearchState(): ThisRecordingSearchStateModel {
  return {
    // The last set of search results that were fetched
    searchResults: undefined,
    // The last search's details
    lastSearchContext: undefined,
    // The most recent string that was searched for
    lastSearchTerm: undefined,
    // The context of the currently viewed playback page
    sttContext: undefined,
    // A way for users without court permissions, to search the currently displayed audio segment.
    permittedToSearchThisAudioSegment: undefined,
  }
}

const searchNotAsked = RemoteData.notAsked()

@State<ThisRecordingSearchStateModel>({
  name: 'thisRecordingSearchState',
  defaults: defaultThisRecordingSearchState(),
})
@Injectable()
export class ThisRecordingSearchState {
  constructor(private readonly searchService: SearchService) {}

  @Selector()
  static state(state: ThisRecordingSearchStateModel): ThisRecordingSearchStateModel {
    return state
  }

  @Selector()
  static lastSearchTerm(state: ThisRecordingSearchStateModel): string | undefined {
    return state.lastSearchTerm
  }

  @Selector()
  static searchResults(state: ThisRecordingSearchStateModel): RemoteData<EntitySearchResponse> {
    return state.searchResults || searchNotAsked
  }

  @Selector()
  static lastSearchContext(state: ThisRecordingSearchStateModel): LastSearchContext | undefined {
    return state.lastSearchContext
  }

  @Selector()
  static sttContext(state: ThisRecordingSearchStateModel): SttContext | undefined {
    return state.sttContext
  }

  @Selector()
  static audioSegmentId(state: ThisRecordingSearchStateModel): Uuid | undefined {
    return state.sttContext === undefined
      ? undefined
      : matchSttContext(state.sttContext, {
          mapAudioOrder: ({ audioSegmentId }) => audioSegmentId,
          mapRecording: () => undefined,
          mapSharedRecording: ({ audioSegmentId }) => audioSegmentId,
        })
  }

  @Selector()
  static recordingId(state: ThisRecordingSearchStateModel): Uuid | undefined {
    return state.sttContext === undefined
      ? undefined
      : matchSttContext(state.sttContext, {
          mapAudioOrder: () => undefined,
          mapRecording: ({ recordingId }) => recordingId,
          mapSharedRecording: () => undefined,
        })
  }

  @Selector()
  static permittedToSearchThisAudioSegment(state: ThisRecordingSearchStateModel): boolean | undefined {
    return state.permittedToSearchThisAudioSegment
  }

  @Action(SetSearchTermAction)
  setLastSearchTerm(
    { patchState }: StateContext<ThisRecordingSearchStateModel>,
    { lastSearchTerm }: SetSearchTermAction,
  ): void {
    patchState({ lastSearchTerm, searchResults: searchNotAsked })
  }

  @Action(GetSearchResultsAction, { cancelUncompleted: true })
  getSearchResults(
    { patchState }: StateContext<ThisRecordingSearchStateModel>,
    { searchTerm, page, searchType, resourceId, pageSize, courtSystemId }: GetSearchResultsAction,
  ): ApiResult<EntitySearchResponse> {
    // If there's no search term...
    if (!searchTerm) {
      return ApiResult.notAsked()
    }

    return this.searchService.doSearchSingular(searchTerm, searchType, courtSystemId, page, resourceId, pageSize).pipe(
      tap((searchResults: RemoteData<EntitySearchResponse>) => {
        patchState({
          searchResults,
          lastSearchContext: {
            searchTerm,
            searchType,
            resourceId,
          },
        })
      }),
    )
  }

  @Action(SetSttContextAction)
  setSttContext(
    { patchState }: StateContext<ThisRecordingSearchStateModel>,
    { sttContext }: SetSttContextAction,
  ): void {
    patchState({ sttContext })
  }

  @Action(SetPermittedToSearchThisAudioSegment)
  setPermittedToSearchThisAudioSegment(
    { patchState }: StateContext<ThisRecordingSearchStateModel>,
    { permittedToSearchThisAudioSegment }: SetPermittedToSearchThisAudioSegment,
  ): void {
    patchState({ permittedToSearchThisAudioSegment })
  }
}
