import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AnchorComponent, IconComponent, StopPropagationDirective } from '@ftr/foundation'
import { ValidationErrorHintDirective } from '../../directives'
import { FlatpickrFactory } from './flatpickr.factory'
import { LegacyDatePickerComponent } from './legacy-date-picker.component'

/**
 * @deprecated use DatePickerComponent instead
 */
@NgModule({
  imports: [CommonModule, IconComponent, ValidationErrorHintDirective, StopPropagationDirective, AnchorComponent],
  declarations: [LegacyDatePickerComponent],
  providers: [FlatpickrFactory],
  exports: [LegacyDatePickerComponent],
})
export class DatePickerModule {}
