import { NgClass } from '@angular/common'
import { Component, Input } from '@angular/core'
import { Data } from '@angular/router'
import { BreadcrumbModule, PageComponent, PageStyle, RemoteData } from '@ftr/foundation'
import { FooterComponent } from '@ftr/ui-core'
import { HeaderModule } from '~app/core/header/header.module'

@Component({
  selector: 'ftr-app-layout-legacy',
  templateUrl: './app-layout-legacy.component.html',
  styleUrls: ['./app-layout-legacy.component.css'],
  standalone: true,
  imports: [PageComponent, BreadcrumbModule, HeaderModule, FooterComponent, NgClass],
})
export class AppLayoutLegacyComponent {
  @Input() logoData: RemoteData<string | undefined> = RemoteData.notAsked()
  @Input() routeData: Data | undefined

  @Input() isPlaybackPage: boolean
  @Input() isFooterVisible: boolean

  readonly defaultPage = PageStyle.Default
}
