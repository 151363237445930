import { ZoneId } from '@js-joda/core'
import moment from 'moment-timezone'
import { DateFormat, jodaFormatPatternWithLocale } from './DateFormat'
import { toZonedDateTime } from './toJoda'
import { FormattableMoment, MomentConvertible, toMoment } from './toMoment'

export interface DateFormatOptions {
  /**
   * The format to format the date in
   */
  format: DateFormat

  /**
   * Custom function for converting a date into a moment instance.
   * If undefined, a default conversion function is used.
   */
  toMoment?: (temporalValue: MomentConvertible) => FormattableMoment

  /**
   * Default value to return if given value is falsy or can't be converted (defaults to empty string)
   */
  defaultValue?: string
}

/**
 * @deprecated Try not to use this as it's deprecated.
 * For joda times and dates, replace with x.format(jodaFormatPatternWithLocale(format)) where possible.
 *
 */
export function toMomentAndFormat(value: MomentConvertible | undefined | null, options: DateFormatOptions): string {
  const { defaultValue = '' } = options

  if (!value) {
    return defaultValue
  }

  const convertToMoment = options.toMoment || toMoment
  let asMoment: FormattableMoment

  try {
    asMoment = convertToMoment(value)
    if (asMoment.supportedFormats.includes(options.format)) {
      return formatMomentWithJoda(asMoment.moment, options.format)
    } else {
      throw new Error(`${value} cannot be formatted with ${options.format}`)
    }
  } catch (error) {
    console.log('Error while converting to moment', { error })
    return defaultValue
  }
}

/**
 * If provided value is a valid date format it
 *
 * Note: We may want to consolidate this and toMomentAndFormat at some point.
 */
export function toMomentAndFormatIfDate(value: string, format: DateFormat = DateFormat.Date): string {
  const momentValue = moment(value, moment.ISO_8601, true)
  if (!momentValue.isValid()) {
    return value
  }
  return formatMomentWithJoda(momentValue, format)
}

// TODO: Convert all format usages to joda
/**
 * Format date string if provided and valid, otherwise returns empty string.
 */
export function toMomentTimezonedDateAndFormatOrEmpty(
  date: string | undefined,
  timezone: ZoneId | string,
  format: DateFormat = DateFormat.IsoLocalDateTime,
): string {
  if (date === undefined) {
    return ''
  }

  if (!moment(date).isValid()) {
    return ''
  }

  return toMomentTimezonedDateAndFormat(date, timezone, format)
}

export function toMomentTimezonedDateAndFormat(date: string, timezone: ZoneId | string, format: DateFormat): string {
  return formatMomentWithJoda(toMoment(date).moment.tz(timezone.toString()), format)
}

/**
 * tl;dr is that we are in the process of migrating from moment to joda.
 *
 * Joda supports all timezones, whereas moment only supports SUPPORTED_TIMEZONES.
 */
export function formatMomentWithJoda(m: moment.Moment, format: DateFormat): string {
  return toZonedDateTime(m).format(jodaFormatPatternWithLocale(format))
}
