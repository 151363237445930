/**
 * Defines what API an API client is meant to access
 */
export enum ApiClientType {
  /**
   * For The Record `ftr-beast`
   */
  api = 'Api',
  /**
   * FTR Regional API `ftr-regional-api`
   */
  regional = 'Regional',
  /**
   * FTR Playback API
   */
  playback = 'Playback',
  /**
   * Court Uploader Server API `ftr-court-uploader-server`
   */
  courtUploaderServer = 'CourtUploaderServer',
  /**
   *  FTR Digital Justice API `ftr-dj-api`
   */
  digitalJustice = 'DigitalJustice',
  /**
   *  FTR Audit API `ftr-regional-audit`
   */
  audit = 'Audit',
  /**
   *  FTR Rest API `ftr-rest-api`
   */
  rest = 'Rest',
}

/**
 * API client configuration
 */
export interface ApiClientConfig {
  /**
   * Client/server declaration
   */
  clientType: ApiClientType
  /**
   * Regional instance that the client should hit
   */
  region?: string
}
