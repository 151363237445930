import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, CustomHttpParameterCodec, serializeHttpParams } from '@ftr/api-shared'
import { PageDetails } from '@ftr/contracts/api/core'
import {
  EntitySearchResponse,
  SearchEntityType,
  SearchManyRequest,
  SearchManyResponse,
  SearchResultType,
  SingleEntitySearchRequest,
  SingleEntitySearchRequestType,
} from '@ftr/contracts/api/search'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, createAndAssign } from '@ftr/foundation'

const DEFAULT_SEARCH_PAGE_SIZE = 10

interface SearchOptions {
  query: string
  searchType: SingleEntitySearchRequestType
  pageNumber: number
  courtSystemId: Uuid
  resourceId?: Uuid
  pageSize?: number
}

interface SearchManyOptions {
  searchTerms: string
  courtSystemId: Uuid
  entities: SearchEntityType[]
  pageToken: string | null
  limit: number
}

@Injectable({
  providedIn: 'root',
})
export class SearchApiService {
  private readonly singleSearchApiClient: ApiClient
  private readonly searchManyApiClient: ApiClient
  constructor(apiClientFactory: ApiClientFactory) {
    this.singleSearchApiClient = apiClientFactory.build('search')
    this.searchManyApiClient = apiClientFactory.build('search/many')
  }

  searchSingular(searchOptions: SearchOptions): ApiResult<EntitySearchResponse> {
    const { courtSystemId, query, pageNumber, resourceId, searchType } = searchOptions
    // Note: we are using this in lieu of adding moment-timezone or js-joda-timezones to our web bundle.
    const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone || ''
    return this.singleSearchApiClient.get<EntitySearchResponse>({
      params: serializeHttpParams(
        createAndAssign(SingleEntitySearchRequest, {
          courtSystemId,
          searchTerms: query as string,
          timeZoneId,
          page: new PageDetails(pageNumber, searchOptions.pageSize ?? DEFAULT_SEARCH_PAGE_SIZE),
          searchType,
          recordingId: searchType === SingleEntitySearchRequestType.ThisRecording ? resourceId : undefined,
          audioSegmentId: searchType === SingleEntitySearchRequestType.ThisAudioSegment ? resourceId : undefined,
        }),
      ),
      // using a custom encoder because timeZoneId could include a + which the default encoder treats as a space
      encoder: new CustomHttpParameterCodec(),
    })
  }

  searchMany(options: SearchManyOptions): ApiResult<SearchManyResponse<SearchResultType>> {
    const { searchTerms, entities, pageToken, limit, courtSystemId } = options
    const request = new SearchManyRequest()
    const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone || ''
    Object.assign<SearchManyRequest, SearchManyRequest>(request, {
      courtSystemId,
      searchTerms,
      timeZoneId,
      entities,
      pageToken,
      limit,
    })

    return this.searchManyApiClient.post<SearchManyResponse<SearchResultType>>({
      body: serializeHttpParams(request),
      encoder: new CustomHttpParameterCodec(),
    })
  }
}
