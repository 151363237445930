import { Injectable } from '@angular/core'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { FeatureName, VocabularyTerms } from '@ftr/contracts/type/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { titleCase } from '@ftr/foundation'
import { StaticMenuItem } from '@ftr/menus'
import { AppPaths, CourtSystemAdminPaths, CourtSystemPaths } from '@ftr/routing-paths'
import { ADMIN_PAGE_PERMISSIONS } from '@ftr/ui-user'

/**
 * Contains navigation items relating to the court system
 */
@Injectable({
  providedIn: 'root',
})
export class CourtSystemNavItems {
  transcriptOrders(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'Transcript Orders',
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.TranscriptOrders],
      permissions: [UserGroupPermissionId.ProcessTranscriptOrders],
    }
  }

  audioOrders(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'Audio Orders',
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.AudioOrders],
      permissions: [UserGroupPermissionId.ProcessAudioOrders],
    }
  }

  realTimeOrders(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'RealTime Orders',
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.RealTimeOrders],
      permissions: [UserGroupPermissionId.ProcessRealTimeOrders],
    }
  }

  recordings(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'Recordings',
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.Recordings],
      permissions: [UserGroupPermissionId.PlaybackRecordings],
    }
  }

  cases(courtSystemId: Uuid, terms: VocabularyTerms): StaticMenuItem {
    return {
      title: `${titleCase(terms.case.plural)}`,
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.Cases],
      permissions: [UserGroupPermissionId.PlaybackHearings],
    }
  }

  reports(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'Reports',
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.Reports],
      permissions: [UserGroupPermissionId.AccessReports],
    }
  }

  adminMenu(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'Admin',
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.Admin],
      permissions: ADMIN_PAGE_PERMISSIONS,
    }
  }

  courtUsers(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'Users',
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.Admin, CourtSystemAdminPaths.Users],
      permissions: [UserGroupPermissionId.AdministerUserGroups],
    }
  }

  userGroups(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'Groups',
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.Admin, CourtSystemAdminPaths.UserGroups],
      permissions: [UserGroupPermissionId.AdministerUserGroups],
    }
  }
  recordingVault(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'Recording Vault',
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.Admin, CourtSystemAdminPaths.Repositories],
      permissions: [UserGroupPermissionId.WriteRecordingVault],
    }
  }

  producers(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'RealTime Recorders',
      path: [
        `/${AppPaths.CourtSystem}`,
        courtSystemId,
        CourtSystemPaths.Admin,
        CourtSystemAdminPaths.RealTimeRecorders,
      ],
      permissions: [UserGroupPermissionId.ViewRealTimeRecorders],
      features: [FeatureName.RealTimeRecorders],
    }
  }

  courthouses(courtSystemId: Uuid, terms: VocabularyTerms): StaticMenuItem {
    return {
      title: `${titleCase(terms?.courthouse.plural || '')}`,
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.Admin, CourtSystemAdminPaths.Courthouses],
      permissions: [UserGroupPermissionId.AdministerLocations],
    }
  }

  holidays(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'Holidays',
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.Admin, CourtSystemAdminPaths.Holidays],
      permissions: [UserGroupPermissionId.AdministerHolidays],
    }
  }

  removeFiles(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'Remove Files',
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.Admin, CourtSystemAdminPaths.RemovalRequests],
      permissions: [UserGroupPermissionId.RemoveTrms],
    }
  }

  billingGroups(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'Billing Groups',
      path: [`/${AppPaths.CourtSystem}`, courtSystemId, CourtSystemPaths.Admin, CourtSystemAdminPaths.BillingGroups],
      permissions: [UserGroupPermissionId.AdministerBillingGroups],
    }
  }
}
