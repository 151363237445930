import { Injectable } from '@angular/core'
import { UserWithGlobalAdministratorRole } from '@ftr/contracts/api/user'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { FeatureFlagKeyOfType, LaunchDarklyFlagValue, WebFeatureFlags } from '../feature-flags'
import { LaunchDarklySdkClientFactory } from '../launch-darkly-client-factory'
import { getIdentifyKeyForUser } from '../launch-darkly.utils'
import { FeatureFlagState } from '../store'

@Injectable()
export class LaunchDarklyService {
  constructor(
    private readonly store: Store,
    private readonly launchDarklySdkClientFactory: LaunchDarklySdkClientFactory,
  ) {}

  observeFlagChanges<T extends LaunchDarklyFlagValue>(key: FeatureFlagKeyOfType<T>): Observable<T> {
    return this.store.select(FeatureFlagState.featureFlag(key))
  }

  getFlagMap(): Observable<WebFeatureFlags> {
    return this.store.select(FeatureFlagState.allFeatureFlags)
  }

  /**
   * Calls the launch darkly api and returns the feature flag variation for a given user.
   * This method should be used in cases where the feature flag or user are not yet available
   * in the store, otherwise the store is the preferred method.
   */
  async getFlagVariationForUser<T extends LaunchDarklyFlagValue>(
    user: UserWithGlobalAdministratorRole,
    key: FeatureFlagKeyOfType<T>,
    courtSystemId?: string,
  ): Promise<T> {
    const ldClient = this.launchDarklySdkClientFactory.getClient()
    await ldClient.identify({
      anonymous: false,
      email: user.email,
      firstName: user.givenName,
      lastName: user.familyName,
      key: getIdentifyKeyForUser({ userId: user.id, email: user.email }),
      custom: {
        userIsGlobalAdmin: user.isGlobalAdministrator,
        userId: user.id,
        ...(courtSystemId ? { courtSystemId } : {}),
      },
    })
    return ldClient.variation(key)
  }

  /**
   * Calls the launch darkly api and returns the feature flag variation for an anonymous user.
   * This method should be used in cases where the user is not available such as public routes.
   * in the store, otherwise the store is the preferred method.
   */
  async getFlagVariationForAnonymous<T extends LaunchDarklyFlagValue>(
    key: FeatureFlagKeyOfType<T>,
    courtSystemId?: string,
  ): Promise<T> {
    const ldClient = this.launchDarklySdkClientFactory.getClient()
    await ldClient.identify({
      anonymous: true,
      custom: {
        userIsGlobalAdmin: false,
        ...(courtSystemId ? { courtSystemId } : {}),
      },
    })
    return ldClient.variation(key)
  }
}
