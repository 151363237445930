import { Injectable } from '@angular/core'
import { isNotNullOrUndefined } from '@ftr/foundation'
import { StaticMenuItem } from '@ftr/menus'
import { UserState } from '@ftr/ui-user'
import { VocabularyState } from '@ftr/ui-vocab'
import { Store } from '@ngxs/store'
import { Observable, combineLatest, filter, map, of, switchMap } from 'rxjs'
// Can't be imported from barrel or else cyclical issues
import { CourtSystemNavItems } from '../../navigation/court-system-nav-items'
import { NavItemsProvider } from '../../navigation/nav-item'

@Injectable({
  providedIn: 'root',
})
export class CourtFooterMenuItemsProvider implements NavItemsProvider {
  constructor(
    private navItems: CourtSystemNavItems,
    private store: Store,
  ) {}

  provide(): Observable<StaticMenuItem[]> {
    return this.store.select(UserState.currentCourtSystem).pipe(
      switchMap(currentCourtSystem => {
        if (!currentCourtSystem || !currentCourtSystem.id) {
          return of([])
        }
        return combineLatest([
          this.store.select(UserState.permissionsInCurrentCourtSystem),
          this.store.select(VocabularyState.termsByCourtSystemId).pipe(
            map(fn => fn(currentCourtSystem.id)),
            filter(isNotNullOrUndefined),
          ),
        ]).pipe(
          switchMap(([permissionsInCurrentCourtSystem, vocabularyTerms]) => {
            const courtSystemId = currentCourtSystem.id
            return of(
              [
                this.navItems.transcriptOrders(courtSystemId),
                this.navItems.audioOrders(courtSystemId),
                this.navItems.realTimeOrders(courtSystemId),
                this.navItems.recordings(courtSystemId),
                this.navItems.cases(courtSystemId, vocabularyTerms),
                this.navItems.reports(courtSystemId),
                this.navItems.adminMenu(courtSystemId),
                this.navItems.courtUsers(courtSystemId),
                this.navItems.recordingVault(courtSystemId),
                this.navItems.courthouses(courtSystemId, vocabularyTerms),
                this.navItems.holidays(courtSystemId),
                this.navItems.removeFiles(courtSystemId),
                this.navItems.billingGroups(courtSystemId),
              ].filter(item => {
                return (
                  !item.permissions ||
                  item.permissions?.length === 0 ||
                  item.permissions?.map(p => permissionsInCurrentCourtSystem?.includes(p)).includes(true)
                )
              }),
            )
          }),
        )
      }),
    )
  }
}
