import { convert, LocalDate, LocalDateTime, LocalTime, ZonedDateTime } from '@js-joda/core'
import moment from 'moment-timezone'
import { ALL_DATE_FORMATS, DateFormat } from './DateFormat'

/**
 * Something which can be converted to a moment instance
 */
export type MomentConvertible = LocalDate | LocalDateTime | LocalTime | ZonedDateTime | string | moment.Moment | Date

/**
 * A moment instance, along with the supported formats it can be formatted to.
 *
 * We need this because moment has no concept of time only dates, whereas our system does, so when
 * a time only date is used we need to ensure that we don't accidentally display it with its date.
 */
export interface FormattableMoment {
  moment: moment.Moment
  supportedFormats: DateFormat[]
}

/**
 * Matches a military time of hours, minutes, and optionally seconds.
 */
const MILITARY_TIME_REGEX = /^(\d{1,2}):(\d{1,2}):?(\d{1,2})?$/

/**
 * Convert something to a moment instance
 */
export function toMoment(temporalValue: MomentConvertible): FormattableMoment {
  if (moment.isMoment(temporalValue)) {
    return {
      moment: temporalValue,
      supportedFormats: ALL_DATE_FORMATS,
    }
  } else if (temporalValue instanceof LocalDate) {
    return {
      moment: moment(temporalValue.toString()),
      supportedFormats: [
        DateFormat.Date,
        DateFormat.DateLong,
        DateFormat.DateLongNoComma,
        DateFormat.DateWithDay,
        DateFormat.DateDayFirst,
        DateFormat.UniversalDate,
      ],
    }
  } else if (temporalValue instanceof LocalTime) {
    return {
      moment: moment(temporalValue.atDate(LocalDate.now()).toString()),
      supportedFormats: [
        DateFormat.Time,
        DateFormat.TimeWithSeconds,
        DateFormat.TimeTwentyFourHourPadded,
        DateFormat.TimeTwentyFourHourWithSeconds,
        DateFormat.TimeWithMilliseconds,
        DateFormat.TimeLeadingZero,
      ],
    }
  } else if (temporalValue instanceof ZonedDateTime) {
    /**
     * `ZonedDateTime` comes from the API as UTC
     * (https://github.com/FTRLabs/ftr-beast/blob/3fbaa13dc4f747b151297f3ebbf1412fc6492b9b/packages/serialization/src/ZonedDateTimeSerializer.ts#L32),
     * so we can safely assume its always UTC and convert it to a local time as such.
     *
     * If in the future we started returning `ZonedDateTime`s which had different timezones we'd probably need
     * to include `joda-timezone` in web so we can convert the joda timezone to UTC. Really hoping that
     * doesn't happen though...
     */
    return {
      moment: moment.utc(convert(temporalValue).toDate()).local(),
      supportedFormats: ALL_DATE_FORMATS,
    }
  } else if (temporalValue instanceof LocalDateTime) {
    return {
      moment: moment(temporalValue.toString()),
      supportedFormats: ALL_DATE_FORMATS,
    }
  } else if (temporalValue instanceof Date) {
    return {
      moment: moment(temporalValue),
      supportedFormats: ALL_DATE_FORMATS,
    }
  } else if (typeof temporalValue === 'string') {
    const momentFromMilitaryTime = militaryTimeStringToMoment(temporalValue)
    if (momentFromMilitaryTime) {
      return {
        moment: momentFromMilitaryTime,
        supportedFormats: [
          DateFormat.Time,
          DateFormat.TimeWithSeconds,
          DateFormat.TimeTwentyFourHourPadded,
          DateFormat.TimeTwentyFourHourWithSeconds,
          DateFormat.TimeWithMilliseconds,
          DateFormat.TimeLeadingZero,
        ],
      }
    }

    const momentFromString = moment(temporalValue.toString())
    if (momentFromString.isValid()) {
      return { moment: momentFromString, supportedFormats: ALL_DATE_FORMATS }
    }
  }
  throw new Error(`Could not convert ${temporalValue} to moment`)
}

function militaryTimeStringToMoment(str: string): moment.Moment | undefined {
  if (MILITARY_TIME_REGEX.test(str)) {
    // Moment has deprecated being able to create an instance using just a time (e.g. "10:00").
    // Because of this, prefix the time with a date. The date will never be displayed to the user anyway
    // Since displaying dates on a time string is prohibited by `FormattableMoment`'s `supportedFormats`.
    const asMoment = moment(`2010-01-01T${str}`)
    if (asMoment.isValid()) {
      return asMoment
    }
  }
  return
}
