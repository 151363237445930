export class RTHNotConfigured extends Error {
  constructor(courtroomName?: string) {
    super(
      `RealTime Hybrid Hearings has not been configured for ${
        courtroomName ? 'courtroom' + courtroomName : 'this courtroom'
      }. Visit the Locations admin page to configure the courtroom`,
    )
    Object.setPrototypeOf(this, RTHNotConfigured.prototype)
  }
}
