import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { PlaybackApiClientSetupService, RegionalApiClientSetupService } from '@ftr/api-regional'
import { ApiClient, ApiClientFactory } from '@ftr/api-shared'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, CombinedError, RemoteData, unwrapData } from '@ftr/foundation'
import { AuthenticationService, UserState } from '@ftr/ui-user'
import { LocalDateTime } from '@js-joda/core'
import { Store } from '@ngxs/store'
import {
  Observable,
  ReplaySubject,
  catchError,
  combineLatest,
  distinct,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  throwError,
} from 'rxjs'
import { PlaybackState } from '../../store'

/**
 * How long a request to set a cookie should be cached for.
 */
const COOKIE_STALE_MINUTES = 1

/**
 * Submits a request with the <code>Auth</code> HTTP header (injected by AuthHttp) for the API to respond with an
 * <code>Auth</code> cookie that can be used to authenticate non-XHR browser requests where specifying a HTTP header
 * isn't possible.
 *
 * Since multiple components may require cookies to be set on a single page, API requests are cached for
 * <code>COOKIE_STALE_MINUTES</code> minutes to prevent excessive requests.
 *
 * @returns {Observable<Response>}
 */
@Injectable({
  providedIn: 'root',
})
export class CookieAuthenticationService {
  readonly validCookie = new ReplaySubject<boolean>(1)

  private cookieLastUpdatedAt: LocalDateTime
  private readonly apiClient: ApiClient
  private readonly currentCourtSystem$: Observable<CourtSystem | undefined>
  private readonly isPlaybackPage$: Observable<boolean>
  private readonly recordingCourtSystemId$: Observable<Uuid | undefined>
  private readonly regionalApiClientSetupService: RegionalApiClientSetupService
  private readonly playbackApiClientSetupService: PlaybackApiClientSetupService

  constructor(
    private readonly store: Store,
    apiClientFactory: ApiClientFactory,
    router: Router,
    authenticationService: AuthenticationService,
    regionalApiClientSetupService: RegionalApiClientSetupService,
    playbackApiClientSetupService: PlaybackApiClientSetupService,
  ) {
    this.isPlaybackPage$ = this.store.select(PlaybackState.isPlaybackPage)
    this.currentCourtSystem$ = this.store.select(UserState.currentCourtSystem)
    this.recordingCourtSystemId$ = this.store.select(PlaybackState.courtSystemId)
    this.regionalApiClientSetupService = regionalApiClientSetupService
    this.playbackApiClientSetupService = playbackApiClientSetupService
    this.apiClient = apiClientFactory.build('/cookie')
    this.updateApiCookie()
      .pipe(distinctUntilChanged())
      .subscribe(auth => {
        this.validCookie.next(auth)
      })

    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        switchMap(() => this.updateApiCookie()),
        map(res => this.validCookie.next(res)),
      )
      .subscribe()

    authenticationService.tokenRefreshed
      .pipe(
        distinctUntilChanged(),
        filter(tokenRefreshed => tokenRefreshed),
        switchMap(tokenRefreshed => this.updateApiCookie(tokenRefreshed)),
        map(res => this.validCookie.next(res)),
      )
      .subscribe()
  }

  expireCookie(): void {
    this.cookieLastUpdatedAt = LocalDateTime.now().minusYears(10)
  }

  /**
   * We return true if the call to set the cookie worked and false if it failed.
   * If it failed, it means you are not authenticated, and won't necessarily be able to download things like:
   * New TRM S3 objects in a playlist, recordings, transcript files from S3, etc.
   *
   * @param forceUpdate This is for cases when the cookie is not stale but the token is about to expire.
   */
  updateApiCookie(forceUpdate = false): Observable<boolean> {
    if (!forceUpdate && !this.cookieIsStale()) {
      return of(true)
    }
    return this.apiClient.post({ withCredentials: true }).pipe(
      unwrapData(),
      catchError(() => of(false)),
      switchMap(result => (result === false ? of(false) : this.updateRegionalApiCookie())),
      switchMap(result => (result === false ? of(false) : this.updatePlaybackApiCookie())),
      catchError(() => of(false)),
      filter(result => (result instanceof RemoteData ? !result.isLoading() : true)),
      map(result => {
        const remoteResult = result instanceof RemoteData ? result.isSuccess() : result

        if (remoteResult) {
          this.cookieLastUpdatedAt = LocalDateTime.now()
        }

        return remoteResult
      }),
    )
  }

  cookieIsStale(): boolean {
    const cookieValidUntil = this.cookieLastUpdatedAt
      ? this.cookieLastUpdatedAt.plusMinutes(COOKIE_STALE_MINUTES)
      : LocalDateTime.now().minusSeconds(1)

    return cookieValidUntil.isBefore(LocalDateTime.now())
  }

  private updateRegionalApiCookie(): Observable<boolean | RemoteData<[null, null], CombinedError<Error>>> {
    return combineLatest([this.currentCourtSystem$, this.isPlaybackPage$, this.recordingCourtSystemId$]).pipe(
      switchMap(([courtSystem, isPlaybackPage, recordingCourtSystemId]) => {
        const currRecordingCourtSystemId = isPlaybackPage && recordingCourtSystemId ? [recordingCourtSystemId] : []
        const currCourtSystemId = courtSystem && courtSystem.id ? [courtSystem.id] : []
        const currCourtSystemIds = [...currCourtSystemId, ...currRecordingCourtSystemId]

        // Only call regional api if there is a courtSystemId (upload and play has no court system)
        if (!currCourtSystemIds.length) {
          return of(true)
        }

        const [currCourSysReq$, recCourtSysReq$ = ApiResult.success(null)] = currCourtSystemIds.map(curr =>
          this.regionalApiClientSetupService.regionalApiClient('cookie', curr).pipe(
            distinct(client => client.baseUrl),
            switchMap(client => client.post<null>({ withCredentials: true })),
          ),
        )

        return ApiResult.combine([currCourSysReq$, recCourtSysReq$])
      }),
      catchError(e => throwError(() => e)),
    )
  }

  private updatePlaybackApiCookie(): Observable<boolean | RemoteData<[null, null], CombinedError<Error>>> {
    return combineLatest([this.currentCourtSystem$, this.isPlaybackPage$, this.recordingCourtSystemId$]).pipe(
      switchMap(([courtSystem, isPlaybackPage, recordingCourtSystemId]) => {
        const currRecordingCourtSystemId = isPlaybackPage && recordingCourtSystemId ? [recordingCourtSystemId] : []
        const currCourtSystemId = courtSystem && courtSystem.id ? [courtSystem.id] : []
        const currCourtSystemIds = [...currCourtSystemId, ...currRecordingCourtSystemId]

        // Only call regional api if there is a courtSystemId (upload and play has no court system)
        if (!currCourtSystemIds.length) {
          return of(true)
        }

        const [currCourSysReq$, recCourtSysReq$ = ApiResult.success(null)] = currCourtSystemIds.map(curr =>
          this.playbackApiClientSetupService.playbackApiClient('cookie', curr).pipe(
            distinct(client => client.baseUrl),
            switchMap(client => client.post<null>({ withCredentials: true })),
          ),
        )

        return ApiResult.combine([currCourSysReq$, recCourtSysReq$])
      }),
      catchError(e => throwError(() => e)),
    )
  }
}
