import { Injectable } from '@angular/core'
import { Favorite, UpdateFavorite } from '@ftr/contracts/api/favorites'
import { Uuid } from '@ftr/contracts/type/shared'
import { ToastService } from '@ftr/foundation'
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { memoize } from 'lodash-es'
import { lastValueFrom } from 'rxjs'
import { FavoritesService } from '../../services'
import {
  AddFavoriteInCourtSystemAction,
  DeleteFavoriteFromCourtSystemAction,
  FetchFavoritesForCourtSystemAction,
  SetFavoritesEditModalVisible,
  SetFavoritesForCourtSystemAction,
  SetFavoritesLoadingState,
  UpdateFavoritesInCourtSystemAction,
} from './favorites.actions'
import { FavoritesStateModel } from './favorites.model'

export function defaultFavoritesState(): FavoritesStateModel {
  return {
    courtSystemFavorites: {},
    showEditModal: false,
    isLoading: false,
  }
}

@State<FavoritesStateModel>({
  name: 'favoritesState',
  defaults: defaultFavoritesState(),
})
@Injectable()
export class FavoritesState {
  constructor(
    private readonly favoritesService: FavoritesService,
    private readonly toastService: ToastService,
  ) {}

  static favorites = memoize((courtSystemId: Uuid): ((state: FavoritesStateModel) => Favorite[] | undefined) => {
    return createSelector([FavoritesState], (state: FavoritesStateModel) => {
      return state.courtSystemFavorites[courtSystemId as Uuid] as Favorite[] | undefined
    })
  })

  @Selector()
  static showEditModal(state: FavoritesStateModel): boolean {
    return state.showEditModal
  }

  @Selector()
  static isLoading(state: FavoritesStateModel): boolean {
    return state.isLoading
  }

  @Action(FetchFavoritesForCourtSystemAction)
  async fetchFavoritesForCourtSystem(
    { getState, dispatch }: StateContext<FavoritesStateModel>,
    { userId, courtSystemId }: FetchFavoritesForCourtSystemAction,
  ): Promise<void> {
    // eslint-disable-next-line security/detect-object-injection
    const cachedFavourites = getState().courtSystemFavorites[courtSystemId]
    if (cachedFavourites && cachedFavourites.length > 0) {
      return
    }
    dispatch(new SetFavoritesLoadingState(true))
    const favourites = await lastValueFrom(this.favoritesService.listFavourites(userId, courtSystemId))
    dispatch(new SetFavoritesLoadingState(false))
    await lastValueFrom(dispatch(new SetFavoritesForCourtSystemAction(courtSystemId, favourites.get()?.results)))
  }

  @Action(SetFavoritesForCourtSystemAction)
  setFavoritesForCourtSystem(
    { setState }: StateContext<FavoritesStateModel>,
    { courtSystemId, favourites }: SetFavoritesForCourtSystemAction,
  ): void {
    setState(
      patch<FavoritesStateModel>({
        courtSystemFavorites: patch({
          [courtSystemId]: favourites,
        }),
      }),
    )
  }

  @Action(AddFavoriteInCourtSystemAction)
  async addFavoriteInCourtSystem(
    { getState, dispatch }: StateContext<FavoritesStateModel>,
    { userId, courtSystemId, url, label }: AddFavoriteInCourtSystemAction,
  ): Promise<void> {
    // eslint-disable-next-line security/detect-object-injection
    const existingFavorites = getState().courtSystemFavorites[courtSystemId] || []

    if (existingFavorites.length >= 3) {
      this.toastService.warning('You have reached your favorites limit. \n Please delete a favorite and try again.')
      return
    }

    dispatch(new SetFavoritesLoadingState(true))
    const response = await lastValueFrom(this.favoritesService.addFavourite(userId, courtSystemId, url, label))
    const newFavorite = response.get()
    dispatch(new SetFavoritesLoadingState(false))

    if (response.isSuccess() && newFavorite) {
      dispatch(new SetFavoritesForCourtSystemAction(courtSystemId, [...existingFavorites, newFavorite]))
    } else {
      this.toastService.error('Failed to add favorite - please try again.')
    }
  }

  @Action(UpdateFavoritesInCourtSystemAction)
  async updateFavoritesInCourtSystem(
    { dispatch }: StateContext<FavoritesStateModel>,
    { courtSystemId, favorites }: UpdateFavoritesInCourtSystemAction,
  ): Promise<void> {
    // The favorites modal can end up empty if we delete all favorites, so this check prevents a failed API
    // request if the user clicks the "save" button
    if (!favorites || favorites.length === 0) {
      return
    }
    const favoriteUpdates: UpdateFavorite[] = favorites.map(({ id, label }) => ({ id, label }))
    const response = await lastValueFrom(this.favoritesService.updateFavorites(favoriteUpdates))
    const updatedFavorites = response.get()?.results
    if (response.isSuccess() && updatedFavorites) {
      dispatch(new SetFavoritesForCourtSystemAction(courtSystemId, updatedFavorites))
    } else {
      this.toastService.error('Failed to edit favorites. Please try again.')
    }
  }

  @Action(DeleteFavoriteFromCourtSystemAction)
  async deleteFavoriteFromCourtSystem(
    { getState, dispatch }: StateContext<FavoritesStateModel>,
    { courtSystemId, favoriteId }: DeleteFavoriteFromCourtSystemAction,
  ): Promise<void> {
    // eslint-disable-next-line security/detect-object-injection
    const currentFavorites = getState().courtSystemFavorites[courtSystemId]
    if (!currentFavorites || !currentFavorites.find(favorite => favorite.id === favoriteId)) {
      return
    }
    const response = await lastValueFrom(this.favoritesService.deleteFavorite(favoriteId))
    if (response.isSuccess()) {
      dispatch(
        new SetFavoritesForCourtSystemAction(
          courtSystemId,
          currentFavorites.filter(favorite => favorite.id !== favoriteId),
        ),
      )
    } else {
      this.toastService.error('Failed to delete favorite. Please try again.')
    }
  }

  @Action(SetFavoritesEditModalVisible)
  setFavoritesEditModalVisible(
    { setState }: StateContext<FavoritesStateModel>,
    { modalVisible }: SetFavoritesEditModalVisible,
  ): void {
    setState(patch({ showEditModal: modalVisible }))
  }

  @Action(SetFavoritesLoadingState)
  setFavoritesLoadingState(
    { setState }: StateContext<FavoritesStateModel>,
    { isLoading }: SetFavoritesLoadingState,
  ): void {
    setState(patch({ isLoading }))
  }
}
