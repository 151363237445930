<ftr-page [pageStyle]="pageStyle">
  <article class="signup">
    <div class="card card--borderless card--shadowless password-card">
      <header class="signup__header">
        <h1 class="password-card__title">Create an account</h1>
        <p class="password-card__subtitle">
          For The Record provides access to the court record, securely and in real time.
        </p>
      </header>

      <ftr-untyped-form
        class="signup__form form-group"
        name="signup"
        [submission]="submission"
        [formGroup]="signupForm"
        (formSubmit)="submitForm(signupForm.getRawValue())"
        submitLabel="Create account"
        submittingLabel="Creating account"
        [formTemplate]="form"
        [submitFullWidth]="true"
        [submitDirection]="undefined"
      >
        <ng-template #form let-highlightErrors="highlightErrors">
          <ul class="form-list">
            <li>
              <ftr-form-input
                name="givenName"
                label="First Name"
                [ngFormControl]="asFormControl(signupForm.controls['givenName'])"
                [required]="true"
                [highlightError]="highlightErrors"
                [notrack]="true"
              >
              </ftr-form-input>
            </li>

            <li>
              <ftr-form-input
                name="familyName"
                label="Last Name"
                [ngFormControl]="asFormControl(signupForm.controls['familyName'])"
                [required]="true"
                [highlightError]="highlightErrors"
                [notrack]="true"
              >
              </ftr-form-input>
            </li>

            <li>
              <ftr-form-input
                name="email"
                label="Email"
                type="email"
                [notrack]="true"
                [spellcheck]="false"
                [ngFormControl]="asFormControl(signupForm.controls['email'])"
                [required]="true"
                [highlightError]="highlightErrors"
              >
              </ftr-form-input>
            </li>

            <li>
              <ftr-checkbox
                [labelTemplate]="checkboxLabel"
                [control]="asFormControl(signupForm.controls['acceptedTerms'])"
                [required]="true"
              >
              </ftr-checkbox>

              <ng-template #checkboxLabel>
                By checking, you agree to our
                <a href="https://www.fortherecord.com/terms-and-conditions/" target="_blank">Terms & Conditions</a> and
                <a href="https://www.fortherecord.com/privacy-policy/" target="_blank">Privacy Policy</a>
              </ng-template>
            </li>

            <li>
              <div [id]="recaptchaElementId"></div>
            </li>
          </ul>
        </ng-template>
      </ftr-untyped-form>
      <ftr-divider [loose]="true">OR</ftr-divider>
      <ftr-single-sign-on [returnUrl]="returnUrl"></ftr-single-sign-on>
      <div class="signup__login-container">
        Already have an account?
        <a [routerLink]="['/login']" [queryParams]="{ returnUrl: returnUrl }" queryParamsHandling="merge"> Log In </a>
      </div>
      <div class="signup__instructions">
        <a class="elevio__create-an-account" [routerLink]="['./']" data-track>How do I create an account?</a>
      </div>
    </div>
  </article>
</ftr-page>
