export const ICONS = {
  AccountBalance: 'account_balance',
  AccountCircle: 'account_circle',
  ActivityLog: 'activity_log',
  Add: 'add',
  AddCircle: 'add_circle',
  AddPerson: 'add_person',
  Agent: 'agent',
  Annotate: 'annotate',
  Apple: 'apple',
  ApproachingDue: 'approaching_due',
  Archive: 'archive',
  ArrowDropDown: 'arrow_drop_down',
  ArrowLeft: 'arrow_left',
  ArrowRight: 'arrow_right',
  Assignment: 'assignment',
  AssignmentReturn: 'assignment_return',
  AssignmentTurnedIn: 'assignment_turned_in',
  Autoscroll: 'autoscroll',
  AutoscrollOff: 'autoscroll_off',
  AwaitingApproval: 'awaiting_approval',
  AwaitingFulfillment: 'awaiting_fulfillment',
  BrokenImage: 'broken_image',
  Calendar: 'calendar',
  Cancel: 'cancel',
  ChangeOrder: 'change_order',
  ChangeOrderDown: 'change_order_down',
  ChangeOrderUp: 'change_order_up',
  CheckCircle: 'check_circle',
  ChevronLeft: 'chevron_left',
  ChevronRight: 'chevron_right',
  Close: 'close',
  Copy: 'copy',
  Courtroom: 'courtroom',
  Courthouse: 'courtroom_participant',
  CourtroomParticipant: 'courtroom_participant',
  CreditCard: 'credit_card',
  Default: 'default',
  Delete: 'delete',
  Departments: 'departments',
  Description: 'description',
  DesktopDeepLink: 'desktop_deep_link',
  DesktopDownload: 'desktop_download',
  DollarSign: 'dollar_sign',
  Domain: 'domain',
  Done: 'done',
  Download: 'download',
  DownloadRecording: 'download_recording',
  DownloadText: 'download_text',
  Drag: 'drag',
  Duration: 'duration',
  Edit: 'edit',
  Email: 'email',
  Error: 'error',
  Export: 'export',
  Event: 'event',
  Facebook: 'facebook',
  FiberManualRecord: 'fiber_manual_record',
  FilterList: 'filter_list',
  Folder: 'folder',
  FolderOpen: 'folder_open',
  FolderOpenLive: 'folder_open_live',
  FolderOpenStop: 'folder_open_stop',
  Forward5: 'forward_5',
  Forward30: 'forward_30',
  FootPedal: 'foot_pedal',
  FullScreen: 'full_screen',
  Gavel: 'gavel',
  Global: 'global',
  Google: 'google',
  GoToNext: 'go_to_next',
  HelpCircle: 'help_circle_solid',
  Hearing: 'hearing',
  Historical: 'historical',
  Home: 'home',
  InProgress: 'in_progress',
  InReview: 'in_review',
  Inactive: 'inactive',
  Info: 'info',
  InfoCircleLine: 'info_circle_line',
  Keyboard: 'keyboard',
  KeyboardArrowDown: 'keyboard_arrow_down',
  KeyboardArrowLeft: 'keyboard_arrow_left',
  KeyboardArrowRight: 'keyboard_arrow_right',
  KeyboardArrowUp: 'keyboard_arrow_up',
  LargerView: 'larger_view',
  Lightbulb: 'lightbulb',
  Link: 'link',
  LinkOff: 'link_off',
  ListAlt: 'list_alt',
  Livestream: 'livestream',
  LocationOn: 'location_on',
  Lock: 'lock',
  LockLive: 'lock_live',
  LockStop: 'lock_stop',
  Logout: 'logout',
  Menu: 'menu',
  Mic: 'mic',
  MicLive: 'mic_live',
  MicStop: 'mic_stop',
  Microsoft: 'microsoft',
  More: 'more',
  MoreVert: 'more_vert',
  MultiTrack: 'multi_track',
  MyOrders: 'my_orders',
  OrdersAudio: 'orders_audio',
  OrdersRealtime: 'orders_realtime',
  OrdersTranscript: 'orders_transcript',
  NavigateDown: 'navigate_down',
  NavigateUp: 'navigate_up',
  NoAccess: 'no_access',
  NoEncryption: 'no_encryption',
  NotInSession: 'not_in_session',
  Off: 'off',
  OpenInNew: 'open_in_new',
  OrderTranscript: 'order_transcript',
  PaperPlane: 'paper_plane',
  Pause: 'pause',
  PauseCircleOutline: 'pause_circle_outline',
  People: 'people',
  Person: 'person',
  PieChart: 'pie_chart',
  PlayArrow: 'play_arrow',
  PlayCircleOutline: 'play_circle_outline',
  PlayerMenu: 'player_menu',
  PlaylistPlayBack: 'playlist_play_back',
  RadioButtonSelected: 'radio_button_selected',
  Recorders: 'recorders',
  RecordingVault: 'recording_vault',
  Reload: 'reload',
  RemoteParticipant: 'remote_participant',
  Remove: 'remove',
  RemoveCircle: 'remove_circle',
  Replay5: 'replay_5',
  Replay30: 'replay_30',
  Resubmission: 'resubmission',
  Sandbox: 'sandbox',
  Schedule: 'schedule',
  Search: 'search',
  Sell: 'sell',
  SendToWaitingRoom: 'send_to_waiting_room',
  Settings: 'settings',
  Share: 'share',
  SharedRecordings: 'shared_recordings',
  SmallerView: 'smaller_view',
  Solo: 'solo',
  StopCircle: 'stop_circle',
  SubArrowRight: 'sub_arrow_right',
  Table: 'table_chart',
  Timezone: 'timezone',
  Transcriber: 'transcriber',
  Transcript: 'transcript',
  TranscriptJobs: 'transcript_jobs',
  TranscriptOutline: 'transcript_outline',
  Translate: 'translate',
  TrmRepositories: 'trm_repositories',
  Unlock: 'unlock',
  Upload: 'upload',
  UploadAndPlay: 'upload_and_play',
  UserAccess: 'user_access',
  VerifiedUser: 'verified_user',
  VerticalBarChart: 'vertical_bar_chart',
  VideoCam: 'videocam',
  VideoCamOff: 'videocam_off',
  Visibility: 'visibility',
  VisibilityOff: 'visibility_off',
  VolumeDown: 'volume_down',
  VolumeOff: 'volume_off',
  VolumeUp: 'volume_up',
  Warning: 'warning',
  Waveform: 'waveform',
  WaveformOff: 'waveform_off',
  Work: 'work',
} as const
export type Icon = keyof typeof ICONS

export enum CardIcon {
  Amex = 'amex',
  AmexGrey = 'amex_grey',
  Diners = 'diners',
  DinersGrey = 'diners_grey',
  Discover = 'discover',
  DiscoverGrey = 'discover_grey',
  EmptyCard = 'empty_card',
  Jcb = 'jcb',
  JcbGrey = 'jcb_grey',
  Mastercard = 'mastercard',
  MastercardGrey = 'mastercard_grey',
  Visa = 'visa',
  VisaGrey = 'visa_grey',
}
