import { RealTimeSttHearingMarker } from './real-time-stt-hearing-marker'
import { RealTimeSttPause } from './real-time-stt-pause'
import { RealTimeSttRemarkWithMetadata } from './real-time-stt-remark-with-metadata'
import { RealTimeSttSealingMarker } from './real-time-stt-sealing-marker'
import { RealTimeSttSessionMarker } from './real-time-stt-session-marker'
import {
  RealTimeSttTranscriptElementBase,
  RealTimeSttTranscriptElementType,
} from './real-time-stt-transcript-element-base'

export interface RealTimeSttMetadata {
  isSealed: boolean
  segmentId: string
  isHearing: boolean
}

// Ensure all our transcript elements correctly implement the ElementBase
type EnforceExtends<T extends RealTimeSttTranscriptElementBase & { type: RealTimeSttTranscriptElementType }> = T
export type RealTimeSttTranscriptElement =
  | EnforceExtends<RealTimeSttPause>
  | EnforceExtends<RealTimeSttRemarkWithMetadata>
  | EnforceExtends<RealTimeSttSessionMarker>
  | EnforceExtends<RealTimeSttSealingMarker>
  | EnforceExtends<RealTimeSttHearingMarker>

export type RealTimeSttTranscriptElementWithMetadata = RealTimeSttTranscriptElement & RealTimeSttMetadata

/**
 * TODO OR-2212 remove when removing `release-sealed-segments` flag
 * @deprecated will be removed with the removal of 'release-sealed-segments' flag
 */
export function isSealedContentMarker(element: RealTimeSttTranscriptElement): element is RealTimeSttRemarkWithMetadata {
  return isRemark(element) && !!element.isSealedContentMarker
}

export function isSessionMarker(
  element: RealTimeSttTranscriptElement,
): element is RealTimeSttSessionMarker & RealTimeSttMetadata {
  return element.type === 'SessionMarker'
}

export function isSealingMarker(
  element: RealTimeSttTranscriptElement,
): element is RealTimeSttSealingMarker & RealTimeSttMetadata {
  return element.type === 'SealingMarker'
}

export function isHearingMarker(
  element: RealTimeSttTranscriptElement,
): element is RealTimeSttHearingMarker & RealTimeSttMetadata {
  return element.type === 'HearingMarker'
}

export function isPause(element: RealTimeSttTranscriptElement): element is RealTimeSttPause & RealTimeSttMetadata {
  return element.type === 'Pause'
}

export function isRemark(element: RealTimeSttTranscriptElement): element is RealTimeSttRemarkWithMetadata {
  return element.type === 'Remark'
}

export const PREVIEW_PANE_REMARK_ID_PREFIX = 'preview-pane-'
