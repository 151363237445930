<div class="time-range">
  <div [class.time-range__controls]="!verticalAlign" [class.time-range__controls--vertical]="verticalAlign">
    <div class="time-range__controls__time">
      <ftr-time-input
        [label]="startLabel"
        name="start"
        [control]="control.controls.start"
        [submitAttempted]="submitAttempted"
        [highlightError]="highlightError"
        [placeholder]="internalPlaceholder"
        [timeFormat]="internalTimeFormat"
        (inputChanged)="propagateInputChangedEvent()"
        [invalidTimeMessage]="invalidTimeMessage"
        [timeStringParseMethods]="timeStringParseMethods"
      ></ftr-time-input>
    </div>
    <div class="time-range__controls__time">
      <ftr-time-input
        [label]="endLabel"
        name="end"
        [control]="control.controls.end"
        [submitAttempted]="submitAttempted"
        [highlightError]="highlightError"
        [placeholder]="internalPlaceholder"
        [timeFormat]="internalTimeFormat"
        (inputChanged)="propagateInputChangedEvent()"
        [invalidTimeMessage]="invalidTimeMessage"
        [timeStringParseMethods]="timeStringParseMethods"
      ></ftr-time-input>
    </div>
  </div>
  <span
    ftrValidationErrorHint
    class="input__validation time-range__range-validation"
    [ngFormControl]="control"
    [controlTouched]="control.touched"
    [submitAttempted]="submitAttempted"
    label="Time Range"
  ></span>
</div>
