import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { DateFormat, momentFormatter } from '@ftr/foundation'
import { Moment } from 'moment-timezone'

export interface MomentValidatorOptions {
  dateFormat?: DateFormat
  maxDate?: Moment
  minDate?: Moment
  maxDateError?: string
  minDateError?: string
  hideMaxDateErrorSuffix?: boolean // If true, the max date label will not be appended to the error message
  hideMinDateErrorSuffix?: boolean // If true, the min date label will not be appended to the error message
  invalidDateError?: string
}

const invalidDateMessage = 'You must enter a valid date.'

export const momentValidator = (options?: MomentValidatorOptions): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: Moment | undefined | null = control.value
    const isValid = value === undefined || value === null || value.isValid()

    if (!isValid) {
      return { invalidDate: options?.invalidDateError ?? invalidDateMessage }
    }

    // Validate that date is within range
    if (options?.maxDate && value?.isAfter(options.maxDate)) {
      const maxDateLabel = options.maxDate.format(momentFormatter(options.dateFormat || DateFormat.Date))
      const maxDateError = options.maxDateError ? `${options.maxDateError}` : 'Date is after maximum allowed date'
      return {
        maxDate: maxDateError + `${options.hideMaxDateErrorSuffix ? '' : ` (${maxDateLabel})`}`,
      }
    }

    if (options?.minDate && value?.isBefore(options.minDate.startOf('day'))) {
      const minDateLabel = options.minDate.format(momentFormatter(options.dateFormat || DateFormat.Date))
      const minDateError = options.minDateError ? `${options.minDateError}` : 'Date is before minimum allowed date'
      return {
        minDate: minDateError + `${options.hideMinDateErrorSuffix ? '' : ` (${minDateLabel})`}`,
      }
    }

    return null
  }
}
