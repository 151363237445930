<div class="form-input-list">
  @for (control of formArray.controls; track control; let i = $index) {
    <span class="form-input-list__row">
      <ftr-form-input
        class="form-input-list__row__control"
        [type]="type"
        [name]="name"
        label="{{ addIndexToLabels ? label + ' ' + (i + 1) : label }}"
        placeholder="{{ addIndexToPlaceholders ? placeholder + ' ' + (i + 1) : placeholder }}"
        [prefix]="prefix"
        [pattern]="pattern"
        [autocomplete]="autocomplete"
        [tabIndex]="tabIndex"
        [ngFormControl]="control"
        [highlightError]="highlightError"
        [notrack]="notrack"
        [required]="required"
        (change)="triggerValidation()"
      >
      </ftr-form-input>
      @if (minimumSorted ? i + 1 > minCount : formArray.controls.length > minCount) {
        <a
          ftrButton
          class="form-input-list__remove"
          [tabIndex]="tabIndex"
          [displayType]="buttonDisplayType.Icon"
          [color]="buttonColor.Grey"
          (keyup.enter)="remove(i)"
          (click)="remove(i)"
        >
          <ftr-icon icon="Close" align="TextBottom"></ftr-icon>
        </a>
      }
    </span>
  }
  @if (!hideAddButton) {
    <ng-container *ngTemplateOutlet="addButtonTemplate"></ng-container>
  }
</div>

<ng-template #addButtonTemplate>
  @if (formArray.controls.length < maxCount) {
    <a
      class="form-input-list__add"
      ftrButton
      [tabIndex]="tabIndex"
      [direction]="undefined"
      [color]="buttonColor.Secondary"
      (keyup.enter)="add()"
      (click)="add()"
    >
      <ftr-icon icon="Add" align="TextBottom"></ftr-icon>
      @if (formArray.controls.length === 0) {
        <span> Add {{ label | ftrIndefinite }}</span>
      }
      @if (formArray.controls.length > 0) {
        <span> Add another {{ label }}</span>
      }
    </a>
  }
</ng-template>
