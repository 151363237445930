import { Injectable } from '@angular/core'
import { apiRequestWithConflict } from '@ftr/api-shared'
import { OnTheRecordTimesConflictResponse } from '@ftr/contracts/regional-api'
import { assertIsDefined } from '@ftr/contracts/shared/Assertions'
import { generateUuid, LocalTimeRange, Uuid } from '@ftr/contracts/type/shared'
import { getPlaybackId, RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import {
  ButtonColor,
  ConfirmationModalService,
  DateFormat,
  jodaFormatPatternWithLocale,
  ModalType,
  tapData,
  tapFailure,
  ToastService,
} from '@ftr/foundation'
import { LoggingService } from '@ftr/ui-observability'
import { VocabularyTermsService } from '@ftr/ui-vocab'
import { LocalTime } from '@js-joda/core'
import { Action, createSelector, Selector, State, StateContext, StateOperator } from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { memoize } from 'lodash-es'
import { firstValueFrom } from 'rxjs'
import { RealTimeSessionsService } from '../../services'
import {
  getSessionMarkerId,
  getSessionMarkersFromOnRecordTimeframes,
  isOngoing,
  parseStartEndTimes,
  RealTimeSttMarkerEditSource,
  RealTimeSttSessionMarker,
} from '../../types'
import {
  mapSessionTimeValidationReasonMessage,
  SessionTimeValidation,
  SessionTimeValidationReason,
  validateSessionTimes,
} from '../../utils'
import { RealTimeSessionsConflictsModalComponent } from './real-time-sessions-conflicts-modal'
import {
  CreateSessionAction,
  DeleteSessionAction,
  SetSessionMarkersAction,
  UpdateSessionAction,
  UpdateSessionLiveLocalTimeAction,
  UpdateSessionRecordingEndTimeAction,
  UpdateSessionRecordingStartTimeAction,
} from './real-time-sessions.actions'
import { RealTimeSessionsUpdatedEvent } from './real-time-sessions.events'
import { RealTimeSessionsModel, RealTimeSessionsStateModel } from './real-time-sessions.model'

export const DEFAULT_NEW_SESSION_LENGTH_SECONDS = 10

const defaultRealTimeSessions = (): RealTimeSessionsModel => ({
  markers: {},
  liveLocalTime: undefined,
  recordingStartTime: LocalTime.MIN,
  recordingEndTime: undefined,
  sessionTimeRanges: [],
  recordingDate: undefined,
  savedMarker: undefined,
})

export const defaultRealTimeSessionsState: () => RealTimeSessionsStateModel = () => ({
  realTimeSessions: {},
})

@State<RealTimeSessionsStateModel>({
  name: 'realTimeSessionsState',
  defaults: defaultRealTimeSessionsState(),
})
@Injectable()
export class RealTimeSessionsState {
  constructor(
    private readonly realTimeSessionsService: RealTimeSessionsService,
    private readonly toastService: ToastService,
    private readonly confirmationModalService: ConfirmationModalService,
    private readonly vocabularyTermsService: VocabularyTermsService,
    private readonly logger: LoggingService,
  ) {}

  @Selector()
  static allRealTimeSessionsStates(state: RealTimeSessionsStateModel): Record<Uuid, RealTimeSessionsModel> {
    return state.realTimeSessions
  }

  static readonly realTimeSessions = memoize(
    (
      playbackKey: RealTimePlaybackKey,
    ): ((
      sourceStates: ReturnType<typeof RealTimeSessionsState.allRealTimeSessionsStates>,
    ) => RealTimeSessionsModel) => {
      return createSelector(
        [RealTimeSessionsState.allRealTimeSessionsStates],
        (sourceStates: ReturnType<typeof RealTimeSessionsState.allRealTimeSessionsStates>) =>
          sourceStates[getPlaybackId(playbackKey)] ?? defaultRealTimeSessions(),
      )
    },
    playbackKey => getPlaybackId(playbackKey),
  )

  static readonly sessionMarkers = memoize(
    (playbackKey: RealTimePlaybackKey): ((sourceStates: RealTimeSessionsModel) => RealTimeSttSessionMarker[]) => {
      const selectedSourceState = RealTimeSessionsState.realTimeSessions(playbackKey)
      return createSelector([selectedSourceState], (state: ReturnType<typeof selectedSourceState>) => {
        if (!state?.markers) {
          return []
        }

        return Object.values(state.markers)
      })
    },
    playbackKey => getPlaybackId(playbackKey),
  )

  @Action(SetSessionMarkersAction)
  setSessionMarkers(
    stateContext: StateContext<RealTimeSessionsStateModel>,
    { playbackKey, recordingDate, timeframes }: SetSessionMarkersAction,
  ): void {
    const playbackId = getPlaybackId(playbackKey)
    const { liveLocalTime, savedMarker } = getOrInitializeRealTimeSessions(stateContext, playbackId)

    const markers = getSessionMarkersFromOnRecordTimeframes(timeframes, recordingDate).reduce<
      Record<string, RealTimeSttSessionMarker>
    >((acc, marker) => {
      const isSavedMarker = savedMarker?.id === marker.id
      // Set the status and the source of the updated marker
      acc[marker.id] = {
        ...marker,
        status: isSavedMarker ? 'saved' : marker.status,
        source: isSavedMarker ? savedMarker?.source : marker.source,
        showAsOngoing: isOngoing(liveLocalTime, marker.sessionTimes),
      }

      return acc
    }, {})

    const sessionTimeRanges = timeframes.map(t => LocalTimeRange.fromTimeframe(t))

    stateContext.setState(
      patchSessions(playbackId, { markers, sessionTimeRanges, recordingDate, savedMarker: undefined }),
    )
  }

  @Action(UpdateSessionRecordingStartTimeAction)
  updateSessionRecordingStartTime(
    { setState }: StateContext<RealTimeSessionsStateModel>,
    { playbackKey, recordingStartTime }: UpdateSessionRecordingStartTimeAction,
  ): void {
    setState(patchSessions(getPlaybackId(playbackKey), { recordingStartTime }))
  }

  @Action(UpdateSessionRecordingEndTimeAction)
  updateSessionRecordingEndTime(
    { setState }: StateContext<RealTimeSessionsStateModel>,
    { playbackKey, recordingEndTime }: UpdateSessionRecordingEndTimeAction,
  ): void {
    setState(patchSessions(getPlaybackId(playbackKey), { recordingEndTime }))
  }

  @Action(UpdateSessionLiveLocalTimeAction)
  updateLiveLocalTime(
    stateContext: StateContext<RealTimeSessionsStateModel>,
    { playbackKey, liveLocalTime }: UpdateSessionLiveLocalTimeAction,
  ): void {
    const playbackId = getPlaybackId(playbackKey)
    const { markers } = getOrInitializeRealTimeSessions(stateContext, playbackId)

    stateContext.setState(
      patchSessions(getPlaybackId(playbackKey), {
        liveLocalTime,
        markers: Object.fromEntries(
          Object.entries(markers ?? {}).map(([id, marker]) => [
            id,
            {
              ...marker,
              showAsOngoing: isOngoing(liveLocalTime, marker.sessionTimes),
            },
          ]),
        ),
      }),
    )
  }

  @Action(CreateSessionAction)
  createSession(
    stateContext: StateContext<RealTimeSessionsStateModel>,
    { playbackKey, startTime, courtSystemId }: CreateSessionAction,
  ): void {
    const { sessionTimeRanges } = getOrInitializeRealTimeSessions(stateContext, getPlaybackId(playbackKey))
    startTime = startTime.withNano(0)

    if (sessionTimeRanges.find(x => x.contains(startTime))) {
      this.toastService.error('Session already exists at that time.')
      return
    }

    const nextSession = sessionTimeRanges
      .toSorted((a, b) => a.start.compareTo(b.start))
      .find(x => x.start.isAfter(startTime))

    let endTime: LocalTime
    if (nextSession && nextSession.start.isBefore(startTime.plusSeconds(DEFAULT_NEW_SESSION_LENGTH_SECONDS))) {
      endTime = nextSession.start
    } else if (startTime.isAfter(LocalTime.MAX.minusSeconds(DEFAULT_NEW_SESSION_LENGTH_SECONDS))) {
      endTime = LocalTime.MAX
    } else {
      endTime = startTime.plusSeconds(DEFAULT_NEW_SESSION_LENGTH_SECONDS)
    }

    const recordingId = playbackKey.recordingId
    assertIsDefined(recordingId, 'Expected RealTimePlaybackKey to include recordingId')

    this.realTimeSessionsService
      .addSession(courtSystemId, recordingId, new LocalTimeRange(startTime, endTime))
      .pipe(
        tapData(() => {
          stateContext.dispatch(new RealTimeSessionsUpdatedEvent(playbackKey))
        }),
        tapFailure(() => {
          this.toastService.error('Error adding session.')
        }),
      )
      .subscribe()
  }

  @Action(DeleteSessionAction)
  async deleteSession(
    { dispatch }: StateContext<RealTimeSessionsStateModel>,
    { playbackKey, timeRange, courtSystemId }: DeleteSessionAction,
  ): Promise<void> {
    const recordingId = playbackKey.recordingId
    assertIsDefined(recordingId, 'Expected RealTimePlaybackKey to include recordingId')

    const terms = await firstValueFrom(this.vocabularyTermsService.observeTerms(courtSystemId))
    const timeFormat = jodaFormatPatternWithLocale(DateFormat.TimeWithSeconds)

    apiRequestWithConflict({
      responseConstructor: OnTheRecordTimesConflictResponse,
      request: (conflictToken: string | undefined) =>
        this.realTimeSessionsService.deleteSession(courtSystemId, recordingId, timeRange, conflictToken),
      onSuccess: () => {
        dispatch(new RealTimeSessionsUpdatedEvent(playbackKey))
      },
      onError: () => {
        this.toastService.error('Error deleting session.')
      },
      onConflict: (
        responseBody: OnTheRecordTimesConflictResponse,
        hasPreviousConflictResolutionTokenExpired: boolean,
      ) => {
        return this.confirmationModalService.confirm({
          modalType: ModalType.Warning,
          title: 'Delete Session',
          confirmText: 'Delete Session',
          confirmButtonColor: ButtonColor.Danger,
          content: {
            type: RealTimeSessionsConflictsModalComponent,
            inputs: {
              title: `Deleting this session from ${timeRange.start.format(timeFormat)} to ${timeRange.end.format(timeFormat)} will affect the following:`,
              terms,
              timeRange,
              conflicts: responseBody.conflicts,
              hasPreviousConflictResolutionTokenExpired,
            },
          },
        })
      },
    })
  }

  @Action(UpdateSessionAction)
  async updateSession(
    stateContext: StateContext<RealTimeSessionsStateModel>,
    { playbackKey, markerId, startTime, endTime, courtSystemId, source }: UpdateSessionAction,
  ): Promise<void> {
    const playbackId = getPlaybackId(playbackKey)
    const { markers, sessionTimeRanges, liveLocalTime, recordingStartTime, recordingEndTime, recordingDate } =
      getOrInitializeRealTimeSessions(stateContext, playbackId)
    const { dispatch, setState } = stateContext
    const marker = markers[markerId]
    const existing = marker?.sessionTimes

    if (!marker || !existing) {
      return
    }

    const parseResult = parseStartEndTimes<SessionTimeValidationReason>({ startTime, endTime })
    if ('parseError' in parseResult) {
      this.handleSessionErrors(playbackId, marker, parseResult.parseError, setState, source)
      return
    }

    const updated = new LocalTimeRange(parseResult.startTime, parseResult.endTime)

    // Status reset if the updated session is the same, useful when undoing changes
    if (updated.equals(existing)) {
      setState(patchSessionMarker(playbackId, markerId, { status: 'loaded' }))
      return
    }

    const timeValidationError = validateSessionTimes(
      parseResult.startTime,
      parseResult.endTime,
      sessionTimeRanges,
      recordingStartTime,
      recordingEndTime,
      existing,
      liveLocalTime,
    )

    if (timeValidationError) {
      this.handleSessionErrors(playbackId, marker, timeValidationError, setState, source)
      return
    }

    setState(
      patchSessionMarker(playbackId, markerId, {
        status: 'saving',
        source,
      }),
    )

    const recordingId = playbackKey.recordingId
    assertIsDefined(recordingId, 'Expected RealTimePlaybackKey to include recordingId')

    apiRequestWithConflict({
      responseConstructor: OnTheRecordTimesConflictResponse,
      request: (conflictToken: string | undefined) => {
        return this.realTimeSessionsService.updateSession(courtSystemId, recordingId, existing, updated, conflictToken)
      },
      onSuccess: () => {
        // Updating savedMarker with its future id just before reloading the recording
        if (recordingDate) {
          setState(
            patchSessions(playbackId, {
              savedMarker: { id: getSessionMarkerId(marker.dividerType, updated, recordingDate), source },
            }),
          )
        }
        dispatch(new RealTimeSessionsUpdatedEvent(playbackKey))
      },
      onError: error => {
        this.logger.error({ message: 'Failed to update session', error })
        setState(
          patchSessionMarker(playbackId, markerId, {
            status: { errorMessage: 'Failed to save.', showRetry: true, showReset: false },
            source,
          }),
        )
      },
      onCancel: () => {
        setState(patchSessionMarker(playbackId, markerId, { status: 'loaded', forceResetFormKey: generateUuid() }))
      },
      onConflict: async (
        responseBody: OnTheRecordTimesConflictResponse,
        hasPreviousConflictResolutionTokenExpired: boolean,
      ) => {
        const terms = await firstValueFrom(this.vocabularyTermsService.observeTerms(courtSystemId))
        const timeFormat = jodaFormatPatternWithLocale(DateFormat.TimeWithSeconds)

        return this.confirmationModalService.confirm({
          modalType: ModalType.Warning,
          title: 'Update Session Times',
          confirmText: 'Update Session Times',
          confirmButtonColor: ButtonColor.Primary,
          content: {
            type: RealTimeSessionsConflictsModalComponent,
            inputs: {
              title: `Updating this session time ${updated.start.format(timeFormat)} to ${updated.end.format(timeFormat)} will affect the following:`,
              terms,
              timeRange: existing,
              conflicts: responseBody.conflicts,
              hasPreviousConflictResolutionTokenExpired,
            },
          },
        })
      },
    })
  }

  private handleSessionErrors(
    playbackId: Uuid,
    marker: RealTimeSttSessionMarker,
    validationResult: SessionTimeValidation,
    setState: (val: StateOperator<RealTimeSessionsStateModel> | RealTimeSessionsStateModel) => void,
    source?: RealTimeSttMarkerEditSource,
  ): void {
    const updatedMarker = {
      ...marker,
      status: {
        errorMessage: mapSessionTimeValidationReasonMessage(validationResult),
        fields: mapValidationReasonToFields(validationResult.reason),
        showRetry: false,
        showReset: true,
      },
      source,
    }

    setState(patchSessionMarker(playbackId, marker.id, updatedMarker))
  }
}

function patchSessions(id: Uuid, changes: Partial<RealTimeSessionsModel>): StateOperator<RealTimeSessionsStateModel> {
  return patch({
    realTimeSessions: patch({
      [id]: patch(changes),
    }),
  })
}

function patchSessionMarker(
  playbackId: Uuid,
  markerId: string,
  changes: Partial<RealTimeSttSessionMarker>,
): StateOperator<RealTimeSessionsStateModel> {
  return patch({
    realTimeSessions: patch({
      [playbackId]: patch({
        markers: patch({
          [markerId]: patch(changes),
        }),
      }),
    }),
  })
}

function getOrInitializeRealTimeSessions(
  { getState, setState }: StateContext<RealTimeSessionsStateModel>,
  playbackId: Uuid,
): RealTimeSessionsModel {
  const originalState = getState()

  let realTimeSessions = originalState.realTimeSessions[playbackId]
  if (!realTimeSessions) {
    realTimeSessions = defaultRealTimeSessions()
    setState(patchSessions(playbackId, realTimeSessions))
  }

  return realTimeSessions
}

function mapValidationReasonToFields(
  validationResult: SessionTimeValidationReason,
): (keyof RealTimeSttSessionMarker)[] {
  const affectsAll = ['both-out-of-bounds', 'already-exists', 'start-must-be-before-end', 'both-are-not-times']
  const startIsInvalid = ['start-is-out-of-bounds', 'start-is-not-a-time', ...affectsAll].includes(validationResult)
  const endIsInvalid = [
    'end-is-out-of-bounds',
    'future-end-time',
    'end-is-not-a-time',
    'end-time-update-for-live-session',
    ...affectsAll,
  ].includes(validationResult)
  const fields: (keyof RealTimeSttSessionMarker)[] = []
  if (startIsInvalid) {
    fields.push('startTime')
  }
  if (endIsInvalid) {
    fields.push('endTime')
  }
  return fields
}
