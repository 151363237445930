import { Injectable } from '@angular/core'
import { SearchApiService } from '@ftr/api-core'
import { RateLimitApiError } from '@ftr/api-shared'
import {
  EntitySearchResponse,
  SearchResult,
  SearchResultType,
  SingleEntitySearchRequestType,
} from '@ftr/contracts/api/search'
import { Uuid, generateUuid } from '@ftr/contracts/type/shared'
import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { toLocalDateTime, toMoment, unwrapData } from '@ftr/foundation'
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { ɵPatchSpec } from '@ngxs/store/operators/patch'
import { memoize } from 'lodash-es'
import { Observable, firstValueFrom } from 'rxjs'
import { isIndexedRemark } from '../../utils'
import {
  ClearRealTimeSearchResultsAction,
  FetchRealTimeSearchResultsAction,
  FetchRealTimeSearchResultsNextPageAction,
  HoverRealTimeSearchResultsAction,
  SelectRealTimeSearchResultsAction,
  SetRealTimeSearchInstanceStateAction,
  SetRealTimeSearchScrollPositionAction,
} from './real-time-search.actions'
import {
  RealTimeSearchResultInteractionUpdateEvent,
  RealTimeSearchResultInteractionUpdateType,
} from './real-time-search.events'
import {
  RealTimeSearchErrorCode,
  RealTimeSearchFieldMatch,
  RealTimeSearchInstanceStateModel,
  RealTimeSearchResult,
  RealTimeSearchStateModel,
} from './real-time-search.model'

const SEARCH_PAGE_SIZE = 30

export function defaultRealTimeSearchState(): RealTimeSearchStateModel {
  return {
    searchInstanceState: {},
  }
}

export function defaultRealTimeSearchInstanceState(): RealTimeSearchInstanceStateModel {
  return {
    searchTerm: '',
    request: undefined,
    results: [],
    pageInfo: undefined,
    selectedSearchResultIndex: undefined,
    hoveredSearchResultIndex: undefined,
    scrollPosition: undefined,
  }
}

@State<RealTimeSearchStateModel>({
  name: 'realTimeSearchState',
  defaults: defaultRealTimeSearchState(),
})
@Injectable()
export class RealTimeSearchState {
  constructor(private readonly searchApiService: SearchApiService) {}

  @Selector()
  static allSearchInstanceStates(state: RealTimeSearchStateModel): RealTimeSearchStateModel['searchInstanceState'] {
    return state.searchInstanceState
  }

  static readonly searchInstanceState = memoize(
    (
      playbackKey: RealTimePlaybackKey,
    ): ((
      sourceStates: ReturnType<typeof RealTimeSearchState.allSearchInstanceStates>,
    ) => RealTimeSearchInstanceStateModel) => {
      return createSelector(
        [RealTimeSearchState.allSearchInstanceStates],
        (sourceStates: ReturnType<typeof RealTimeSearchState.allSearchInstanceStates>) =>
          sourceStates[getSearchId(playbackKey)] ?? defaultRealTimeSearchInstanceState(),
      )
    },
    playbackKey => getSearchId(playbackKey),
  )

  @Action(SetRealTimeSearchInstanceStateAction)
  async setRealTimeSearchInstanceState(
    { setState }: StateContext<RealTimeSearchStateModel>,
    { playbackKey, instanceState }: SetRealTimeSearchInstanceStateAction,
  ): Promise<void> {
    setState(
      patch<RealTimeSearchStateModel>({
        searchInstanceState: patch({
          [getSearchId(playbackKey)]: instanceState,
        }),
      }),
    )
  }

  @Action(FetchRealTimeSearchResultsAction)
  async fetchRealTimeSearchResults(
    { setState, getState, dispatch }: StateContext<RealTimeSearchStateModel>,
    { playbackKey, courtSystemId, searchTerm }: FetchRealTimeSearchResultsAction,
  ): Promise<void> {
    const requestId = generateUuid()
    const searchId = getSearchId(playbackKey)
    await createDefaultInstanceStateIfNotExists(getState, dispatch, playbackKey)

    patchSearchInstanceState(setState, searchId, {
      ...defaultRealTimeSearchInstanceState(),
      searchTerm,
      request: { requestId, type: 'initial', loading: true, error: undefined },
    })

    // Fetch clears the hover/selected states, so we emit a clear event here as well
    dispatchResultInteractionUpdateEvent(dispatch, getState, playbackKey, 'Clear')

    runWithoutWaitingForActionToEnd(async () => {
      const searchRequestResource =
        playbackKey.type === 'audio-segment'
          ? { resourceId: playbackKey.audioSegmentId, searchType: SingleEntitySearchRequestType.ThisAudioSegment }
          : { resourceId: playbackKey.recordingId, searchType: SingleEntitySearchRequestType.ThisRecording }

      let searchResult: EntitySearchResponse<SearchResultType>
      try {
        searchResult = await firstValueFrom(
          this.searchApiService
            .searchSingular({
              courtSystemId,
              pageNumber: 1,
              query: searchTerm,
              pageSize: SEARCH_PAGE_SIZE,
              ...searchRequestResource,
            })
            .pipe(unwrapData()),
        )
      } catch (error) {
        const currentRequestId = getState().searchInstanceState[searchId]?.request?.requestId
        // If the user has searched a different searchTerm in the meantime, we just ignore this result
        if (requestId !== currentRequestId) {
          return
        }

        patchSearchInstanceState(setState, searchId, {
          request: {
            requestId,
            type: 'initial',
            error: getErrorCode(error),
            loading: false,
          },
        })
        return
      }

      const currentRequestId = getState().searchInstanceState[searchId]?.request?.requestId
      // If the user has searched a different searchTerm in the meantime, we just ignore this result
      if (requestId !== currentRequestId) {
        return
      }

      patchSearchInstanceState(setState, searchId, {
        searchTerm,
        results: searchResult.items.map(mapElasticSearchResultToRealTimeSearchResult),
        pageInfo: {
          currentPage: searchResult.meta.number,
          numResults: searchResult.meta.totalItems,
          morePages: searchResult.meta.number < searchResult.meta.totalItems / searchResult.meta.size,
        },
        request: {
          requestId,
          type: 'initial',
          loading: false,
          error: undefined,
        },
      })
    })
  }

  @Action(FetchRealTimeSearchResultsNextPageAction)
  async fetchRealTimeSearchResultsNextPage(
    { setState, getState }: StateContext<RealTimeSearchStateModel>,
    { playbackKey, courtSystemId }: FetchRealTimeSearchResultsNextPageAction,
  ): Promise<void> {
    const requestId = generateUuid()
    const searchId = getSearchId(playbackKey)
    const originalState = getState().searchInstanceState[searchId]
    if (!originalState?.pageInfo || !originalState.pageInfo.morePages) {
      // Can't get a next page for a search result which does not exist
      return
    }

    if (originalState.request?.type === 'next-page' && originalState.request.loading) {
      // Don't fetch again if there is already a next page request in flight
      return
    }

    patchSearchInstanceState(setState, searchId, {
      request: { requestId, type: 'next-page', loading: true, error: undefined },
    })

    const nextPageNumber = originalState.pageInfo.currentPage + 1
    runWithoutWaitingForActionToEnd(async () => {
      const searchRequestResource =
        playbackKey.type === 'audio-segment'
          ? { resourceId: playbackKey.audioSegmentId, searchType: SingleEntitySearchRequestType.ThisAudioSegment }
          : { resourceId: playbackKey.recordingId, searchType: SingleEntitySearchRequestType.ThisRecording }

      let searchResult: EntitySearchResponse<SearchResultType>

      try {
        searchResult = await firstValueFrom(
          this.searchApiService
            .searchSingular({
              courtSystemId,
              pageNumber: nextPageNumber,
              query: originalState.searchTerm,
              pageSize: SEARCH_PAGE_SIZE,
              ...searchRequestResource,
            })
            .pipe(unwrapData()),
        )
      } catch (error) {
        const currentRequestId = getState().searchInstanceState[searchId]?.request?.requestId
        // If the user has searched a different searchTerm in the meantime, we just ignore this result
        if (requestId !== currentRequestId) {
          return
        }

        patchSearchInstanceState(setState, searchId, {
          request: {
            requestId,
            type: 'next-page',
            error: getErrorCode(error),
            loading: false,
          },
        })
        return
      }

      const currentRequestId = getState().searchInstanceState[searchId]?.request?.requestId
      // If the user has searched a different searchTerm in the meantime, we just ignore this result
      if (requestId !== currentRequestId) {
        return
      }

      patchSearchInstanceState(setState, searchId, {
        results: [...originalState.results, ...searchResult.items.map(mapElasticSearchResultToRealTimeSearchResult)],
        pageInfo: {
          currentPage: searchResult.meta.number,
          numResults: searchResult.meta.totalItems,
          morePages: searchResult.meta.number < searchResult.meta.totalItems / searchResult.meta.size,
        },
        request: {
          requestId,
          type: 'next-page',
          loading: false,
          error: undefined,
        },
      })
    })
  }

  @Action(ClearRealTimeSearchResultsAction)
  async clearRealTimeSearchResults(
    { dispatch, setState, getState }: StateContext<RealTimeSearchStateModel>,
    { playbackKey }: ClearRealTimeSearchResultsAction,
  ): Promise<void> {
    const searchId = getSearchId(playbackKey)
    const originalState = getState().searchInstanceState[searchId]
    if (!originalState) {
      // No need to clear state which doesn't exist
      return
    }

    setState(
      patch<RealTimeSearchStateModel>({
        searchInstanceState: patch({
          [searchId]: undefined as unknown as RealTimeSearchInstanceStateModel,
        }),
      }),
    )

    dispatchResultInteractionUpdateEvent(dispatch, getState, playbackKey, 'Clear')
  }

  @Action(SelectRealTimeSearchResultsAction)
  async selectRealTimeSearchResults(
    { dispatch, getState, setState }: StateContext<RealTimeSearchStateModel>,
    { playbackKey, selectedSearchResultIndex }: SelectRealTimeSearchResultsAction,
  ): Promise<void> {
    const searchId = getSearchId(playbackKey)
    await createDefaultInstanceStateIfNotExists(getState, dispatch, playbackKey)

    patchSearchInstanceState(setState, searchId, {
      selectedSearchResultIndex,
    })

    dispatchResultInteractionUpdateEvent(dispatch, getState, playbackKey, 'Select')
  }
  @Action(HoverRealTimeSearchResultsAction)
  async hoveredRealTimeSearchResults(
    { dispatch, getState, setState }: StateContext<RealTimeSearchStateModel>,
    { playbackKey, hoveredSearchResultIndex }: HoverRealTimeSearchResultsAction,
  ): Promise<void> {
    const searchId = getSearchId(playbackKey)
    await createDefaultInstanceStateIfNotExists(getState, dispatch, playbackKey)

    patchSearchInstanceState(setState, searchId, {
      hoveredSearchResultIndex,
    })
    dispatchResultInteractionUpdateEvent(dispatch, getState, playbackKey, 'Hover')
  }

  @Action(SetRealTimeSearchScrollPositionAction)
  async setRealTimeSearchScrollPosition(
    { dispatch, getState, setState }: StateContext<RealTimeSearchStateModel>,
    { playbackKey, scrollPosition }: SetRealTimeSearchScrollPositionAction,
  ): Promise<void> {
    const searchId = getSearchId(playbackKey)
    await createDefaultInstanceStateIfNotExists(getState, dispatch, playbackKey)
    patchSearchInstanceState(setState, searchId, {
      scrollPosition,
    })
  }
}

function mapElasticSearchResultToRealTimeSearchResult(result: SearchResult<SearchResultType>): RealTimeSearchResult {
  const body = result.body
  if (!isIndexedRemark(body)) {
    throw new Error('Unexpected non remark result')
  }

  const timeZoneId = body.timeZoneId
  if (!timeZoneId) {
    throw new Error('No timezoneId on real-time search result')
  }

  const highlightedContent = result.highlight?.['transcript.content']
  const highlightedSpeaker = result.highlight?.['transcript.speakerName']

  const contentMatch = new RealTimeSearchFieldMatch(body.content, highlightedContent)
  const speakerNameMatch = new RealTimeSearchFieldMatch(body.speakerName, highlightedSpeaker)

  return {
    remarkId: body.id,
    startTime: toLocalDateTime(toMoment(body.startTime).moment.tz(timeZoneId)),
    endTime: toLocalDateTime(toMoment(body.endTime).moment.tz(timeZoneId)),
    contentMatch,
    speakerNameMatch,
  }
}

async function createDefaultInstanceStateIfNotExists(
  getState: () => RealTimeSearchStateModel,
  dispatch: (actions: any) => Observable<void>,
  playbackKey: RealTimePlaybackKey,
): Promise<RealTimeSearchInstanceStateModel> {
  const searchId = getSearchId(playbackKey)
  let originalState = getState().searchInstanceState[searchId]
  if (!originalState) {
    originalState = defaultRealTimeSearchInstanceState()
    await firstValueFrom(dispatch(new SetRealTimeSearchInstanceStateAction(playbackKey, originalState)))
  }
  return originalState
}

function patchSearchInstanceState(
  setState: StateContext<RealTimeSearchStateModel>['setState'],
  searchId: Uuid,
  update: ɵPatchSpec<RealTimeSearchInstanceStateModel>,
): void {
  setState(
    patch<RealTimeSearchStateModel>({
      searchInstanceState: patch({
        [searchId]: patch(update),
      }),
    }),
  )
}
function dispatchResultInteractionUpdateEvent(
  dispatch: (actions: any) => Observable<void>,
  getState: () => RealTimeSearchStateModel,
  playbackKey: RealTimePlaybackKey,
  updateType: RealTimeSearchResultInteractionUpdateType,
): void {
  const searchId = getSearchId(playbackKey)

  const latestState = getState().searchInstanceState[searchId]

  const hoveredIndex = latestState?.hoveredSearchResultIndex
  const selectedIndex = latestState?.selectedSearchResultIndex
  dispatch(
    new RealTimeSearchResultInteractionUpdateEvent(playbackKey, updateType, {
      hovered: hoveredIndex !== undefined ? latestState?.results[hoveredIndex] : undefined,
      selected: selectedIndex !== undefined ? latestState?.results[selectedIndex] : undefined,
    }),
  )
}

function getSearchId(key: RealTimePlaybackKey): Uuid {
  if (key.type === 'audio-segment') {
    return key.audioSegmentId
  }
  return key.recordingId
}

function runWithoutWaitingForActionToEnd(callback: () => Promise<void>): void {
  void callback()
}

function getErrorCode(error: unknown): RealTimeSearchErrorCode {
  if (error instanceof RateLimitApiError) {
    return RealTimeSearchErrorCode.RateLimit
  }
  return RealTimeSearchErrorCode.Unknown
}
