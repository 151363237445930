// eslint-disable-next-line max-classes-per-file
import { Uuid } from '@ftr/contracts/type/shared'
import { LocalDate } from '@js-joda/core'

export class InitialiseUpcomingHearingsListCommand {
  static readonly type = '[Upcoming Hearings List] Initialise Upcoming Hearings List'
  constructor(
    readonly courtSystemId: Uuid,
    readonly courtroomId: Uuid,
    readonly date: LocalDate,
  ) {}
}

export class FetchUpcomingHearingsPageCommand {
  static readonly type = '[Upcoming Hearings List] Fetch Upcoming Hearings Page'
  constructor(
    readonly instanceId: string,
    readonly offset: number,
    readonly limit: number,
  ) {}
}

export class FetchNextUpcomingHearingsPageCommand {
  static readonly type = '[Upcoming Hearings List] Fetch Next Upcoming Hearings Page'
  constructor(
    readonly courtroomId: Uuid,
    readonly date: LocalDate,
  ) {}
}
