import { Injectable } from '@angular/core'
import { DesktopDownloadUrlService } from '@ftr/api-core'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { unwrapData } from '@ftr/foundation'
import { AppPaths, OrderPaths } from '@ftr/routing-paths'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { Observable, combineLatest, map } from 'rxjs'
import { SideNavGroup, SideNavItem, getGlobalAdminNavItem, getHelpSupportNavItem } from '../side-nav-item'

const DEFAULT_NAV_ITEMS = {
  'transcript-jobs': {
    type: 'LINK',
    title: 'Transcript Jobs',
    path: [`/${AppPaths.Jobs}`],
    icon: 'TranscriptJobs',
  } as SideNavItem,
  'upload-and-play': {
    type: 'LINK',
    title: 'Upload & Play',
    path: [`/${AppPaths.Uploads}`],
    icon: 'UploadAndPlay',
  } as SideNavItem,
  'my-orders': {
    type: 'LINK',
    title: 'My Orders',
    path: [`/${AppPaths.Orders}`],
    icon: 'MyOrders',
  } as SideNavItem,
  'shared-recordings': {
    type: 'LINK',
    title: 'Shared Recordings',
    path: [`/${AppPaths.SharedRecordings}`],
    icon: 'SharedRecordings',
  } as SideNavItem,
  'order-audio': {
    type: 'LINK',
    title: 'Audio',
    path: [`/${AppPaths.Orders}`, OrderPaths.ChooseCourt, OrderPaths.Audio],
    icon: 'OrdersAudio',
    tooltipHint: 'Place an Audio Order',
  } as SideNavItem,
  'order-real-time': {
    type: 'LINK',
    title: 'RealTime',
    path: [`/${AppPaths.Orders}`, OrderPaths.ChooseCourt, OrderPaths.RealTime],
    icon: 'OrdersRealtime',
    tooltipHint: 'Place a RealTime Order',
  } as SideNavItem,
  'order-transcript': {
    type: 'LINK',
    title: 'Transcript',
    path: [`/${AppPaths.Orders}`, OrderPaths.ChooseCourt, OrderPaths.Transcript],
    icon: 'OrdersTranscript',
    tooltipHint: 'Place a Transcript Order',
  } as SideNavItem,
  'select-court-system': {
    type: 'LINK',
    title: 'Select a Court System',
    path: ['/court-system'],
    icon: 'Gavel',
  } as SideNavItem,
}

@Injectable({
  providedIn: 'root',
})
export class ExternalUserNavItemsProvider {
  constructor(
    private readonly store: Store,
    private readonly desktopDownloadUrlService: DesktopDownloadUrlService,
  ) {}

  provide(): Observable<{ navGroups: SideNavGroup[]; footerItems: SideNavItem[] }> {
    return combineLatest([
      this.store.select(UserState.courtSystems).pipe(unwrapData()),
      this.store
        .select(UserState.hasPermissionInAnyCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.TranscribeRecordings))),
      this.store.select(UserState.isGlobalAdmin),
      this.store
        .select(UserState.hasOnlyPermissionInAllCourtSystems)
        .pipe(map(fn => fn(UserGroupPermissionId.TranscribeRecordings))),
    ]).pipe(
      map(([courtSystems, hasTranscriberPermissions, isGlobalAdmin, hasOnlyTranscriberPermission]) => ({
        navGroups: [
          ...(hasTranscriberPermissions ? [{ items: [DEFAULT_NAV_ITEMS['transcript-jobs']] }] : []),
          {
            title: 'My content',
            items: [
              DEFAULT_NAV_ITEMS['upload-and-play'],
              DEFAULT_NAV_ITEMS['my-orders'],
              DEFAULT_NAV_ITEMS['shared-recordings'],
            ],
          },
          {
            title: 'Place an order',
            items: [
              DEFAULT_NAV_ITEMS['order-audio'],
              DEFAULT_NAV_ITEMS['order-real-time'],
              DEFAULT_NAV_ITEMS['order-transcript'],
            ],
          },
        ],
        footerItems: [
          ...(isGlobalAdmin ? [getGlobalAdminNavItem()] : []),
          ...(courtSystems?.length > 0 && !hasOnlyTranscriberPermission
            ? [DEFAULT_NAV_ITEMS['select-court-system']]
            : []),
          getHelpSupportNavItem(this.desktopDownloadUrlService.getDownloadUrl()),
        ],
      })),
    )
  }
}
