/* eslint-disable max-classes-per-file */
import { SingleEntitySearchRequestType } from '@ftr/contracts/api/search'
import { Uuid } from '@ftr/contracts/type/shared'
import { SttContext } from '@ftr/stt-search'

export class GetSearchResultsAction {
  static readonly type = '[Search] Get Search Results'

  constructor(
    public searchTerm: string | undefined,
    public searchType: SingleEntitySearchRequestType,
    public courtSystemId: Uuid,
    public resourceId: Uuid,
    public page?: number | undefined,
    public pageSize?: number | undefined,
  ) {}
}

export class SetSearchTermAction {
  static readonly type = '[Search] Set Last Search Term'

  constructor(public lastSearchTerm: string) {}
}

export class SetSttContextAction {
  static readonly type = '[Search] Set SttContext'

  constructor(public sttContext: SttContext | undefined) {}
}

export class SetPermittedToSearchThisAudioSegment {
  static readonly type = '[Search] Set PermittedToSearchThisAudioSegment'

  constructor(public permittedToSearchThisAudioSegment: boolean) {}
}
