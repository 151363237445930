<div class="search">
  <form name="searchForm" class="search__form" [formGroup]="formGroup" (ngSubmit)="onSearchButtonClick()">
    <button
      ftrButton
      [displayType]="buttonDisplayType.Icon"
      class="search__submit"
      type="submit"
      aria-label="submit"
      tabindex="-1"
    >
      <ftr-icon icon="Search" color="GreyLight" align="Top"></ftr-icon>
    </button>
    <div class="search__input input" [@expandSearch]>
      <input
        #searchIpt
        type="text"
        class="input__control"
        name="searchIpt"
        autocomplete="off"
        [placeholder]="mobileViewport() ? 'Search' : 'Type search term here...'"
        [formControl]="asFormControl(formGroup.controls['searchTerm'])"
        (keyup)="onSearchInputChange()"
        (keydown.arrowDown)="onDown($event)"
        aria-label="search content in this court system"
        [attr.aria-invalid]="formGroup.controls['searchTerm'].touched && !formGroup.controls['searchTerm'].valid"
        data-testid="stt-search-input"
      />
      @if (formGroup.value.searchTerm?.length > 0) {
        <a
          class="search__clear"
          type="button"
          ftrButton
          [displayType]="buttonDisplayType.Text"
          (click)="clearSearch()"
          (keyup.enter)="clearSearch()"
          (keyup.space)="clearSearch()"
          tabindex="0"
          aria-label="clear search contents"
        >
          Clear
        </a>
      }
    </div>
  </form>
  <button
    class="search__back"
    (click)="closeSearch()"
    (keydown.enter)="closeSearch()"
    tabindex="0"
    aria-label="close search"
  >
    <ftr-icon icon="Close" color="Grey" [interactive]="false" align="Top"></ftr-icon>
  </button>
</div>
