import { DateFormat, jodaFormatPatternWithLocale } from '@ftr/foundation'
import { LocalDate } from '@js-joda/core'

export function getComplexDateFilterFormattedDate(value: LocalDate | string | undefined): string | undefined {
  if (!value) {
    return undefined
  }

  try {
    const date = typeof value === 'string' ? LocalDate.parse(value) : value
    return date.format(jodaFormatPatternWithLocale(DateFormat.UniversalDate))
  } catch {
    return undefined
  }
}
