import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import {
  RemoteData,
  ScreenSize,
  ScreenSizeService,
  SizesService,
  isMobileScreenSize,
  isNotNullOrUndefined,
} from '@ftr/foundation'
import { LogoTheme } from '@ftr/ui-core'
import { PlaybackState } from '@ftr/ui-playback'
import { ADMIN_PAGE_PERMISSIONS, UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { Observable, combineLatest, distinctUntilChanged, filter, map, of, switchMap } from 'rxjs'

@Component({
  selector: 'ftr-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Input() logoData: RemoteData<string | undefined>

  isInternalUser$: Observable<boolean>
  displayCourtDropdownOrLabel$: Observable<boolean>
  isLoggedIn$: Observable<boolean>
  displayCourtSystemDropdown$: Observable<boolean>
  currentCourtSystem$: Observable<CourtSystem>

  private currentScreenSize: ScreenSize

  readonly isPlaybackPage$: Observable<boolean>

  readonly logoThemes = LogoTheme

  private readonly courtSelectorPermissions = [
    ...ADMIN_PAGE_PERMISSIONS,
    UserGroupPermissionId.AccessReports,
    UserGroupPermissionId.PlaybackRecordings,
    UserGroupPermissionId.ReadStt,
    UserGroupPermissionId.RequestStt,
    UserGroupPermissionId.ViewRealTimeRecorders,
    UserGroupPermissionId.ProcessAudioOrders,
    UserGroupPermissionId.ProcessRealTimeOrders,
    UserGroupPermissionId.ProcessTranscriptOrders,
  ]

  @ViewChild('header')
  set header(el: ElementRef<HTMLDivElement> | undefined) {
    // wait a tick because the component's view has already been checked
    setTimeout(() => this.headerSizeService.headerIsRendered.next(!!el), 0)
  }

  constructor(
    private readonly store: Store,
    private readonly screenSizeService: ScreenSizeService,
    private readonly headerSizeService: SizesService,
  ) {
    this.isPlaybackPage$ = this.store.select(PlaybackState.isPlaybackPage)
  }

  ngOnInit(): void {
    this.setupCourtSelectorVisibility()

    this.isLoggedIn$ = this.store.select(UserState.isLoggedIn).pipe(distinctUntilChanged())
    this.screenSizeService.size.subscribe(size => (this.currentScreenSize = size))
    this.currentCourtSystem$ = this.store.select(UserState.currentCourtSystem).pipe(filter(isNotNullOrUndefined))
    this.isInternalUser$ = this.store.select(UserState.isInternalUser)
  }

  get isMobile(): boolean {
    return isMobileScreenSize(this.currentScreenSize)
  }

  get isTablet(): boolean {
    return this.currentScreenSize === ScreenSize.Medium
  }

  get isDesktop(): boolean {
    return this.currentScreenSize === ScreenSize.Large
  }

  get hasNoPersonalizedLogo(): boolean {
    return (
      this.logoData.isCompleted() &&
      !(this.logoData.isSuccess() && this.logoData._data && this.logoData._data.length > 1)
    )
  }

  get logoUrl(): string | undefined {
    return this.logoData.isSuccess() ? this.logoData._data : undefined
  }

  private setupCourtSelectorVisibility(): void {
    this.displayCourtDropdownOrLabel$ = this.store.select(UserState.hasAnyPermissionInCourtSystem).pipe(
      map(fn => fn(this.courtSelectorPermissions)),
      distinctUntilChanged(),
    )

    this.displayCourtSystemDropdown$ = combineLatest([
      this.store.select(UserState.currentUserGroups),
      this.store.select(UserState.hasAdminPermissionsInCourtSystem),
    ]).pipe(
      switchMap(([userGroups, hasAdminPermissions]) => {
        const courtSystems = new Set(userGroups.data?.map(s => s.courtSystemId))
        return of(hasAdminPermissions || courtSystems.size > 1)
      }),
    )
  }
}
