import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { SelectComponent, ValidationErrorHintDirective } from '@ftr/forms'
import { AnchorComponent, ButtonComponent, IconComponent, TooltipModule } from '@ftr/foundation'
import { DropdownMenuComponent } from '@ftr/menus'
import { CourtSystemLogoComponent, FtrLogoComponent, HeaderLoggedOutComponent, SideNavModule } from '@ftr/ui-core'
import { AccountMenuComponent } from '~app/core/header/account-menu/account-menu.component'
import { CourtSystemDropdownComponent } from '~app/core/header/court-system-dropdown/court-system-dropdown.component'
import { MainSideNavComponent } from '~app/core/header/main-side-nav/main-side-nav.component'
import { DesktopModule } from '~app/features/desktop/desktop.module'
import { FtrFormsModule } from '~app/shared/ftr-forms.module'
import { LegacyOverflowMenuComponent } from '~app/shared/overflow-menu/legacy-overflow-menu.component'
import { HeaderComponent } from './header.component'

@NgModule({
  imports: [
    AnchorComponent,
    ButtonComponent,
    CommonModule,
    CourtSystemLogoComponent,
    DesktopModule,
    DropdownMenuComponent,
    FtrFormsModule,
    FtrLogoComponent,
    IconComponent,
    LegacyOverflowMenuComponent,
    RouterModule,
    SelectComponent,
    SideNavModule,
    TooltipModule,
    ValidationErrorHintDirective,
    AccountMenuComponent,
    HeaderLoggedOutComponent,
  ],
  declarations: [CourtSystemDropdownComponent, MainSideNavComponent, HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
