import { Injectable } from '@angular/core'
import { ApiClient } from '@ftr/api-shared'
import { Uuid } from '@ftr/contracts/type/shared'
import { Observable } from 'rxjs'
import { PlaybackApiClientFactory } from './playback-api-client.factory'

@Injectable({
  providedIn: 'root',
})
export class PlaybackApiClientSetupService {
  readonly playbackApiClientMap = new Map<string, Observable<ApiClient>>()

  constructor(private readonly playbackApiClientFactory: PlaybackApiClientFactory) {}

  playbackApiClient(path: string, courtSystemId: Uuid): Observable<ApiClient> {
    const uniqueKey = `${path}-${courtSystemId}`
    if (!this.playbackApiClientMap.has(uniqueKey)) {
      this.playbackApiClientMap.set(uniqueKey, this.playbackApiClientFactory.buildForCourt(path, courtSystemId))
    }
    return this.playbackApiClientMap.get(uniqueKey)!
  }
}
