import { Injectable } from '@angular/core'
import { Url } from '@ftr/contracts/type/shared'
import { BrowserFeaturesService, BrowserSnifferService } from '@ftr/foundation'

@Injectable()
export class MultiChannelPlaylistService {
  constructor(
    private readonly browserFeaturesService: BrowserFeaturesService,
    private readonly browserSnifferService: BrowserSnifferService,
  ) {}

  getMultiChannelUrlWhenValid(url: Url, hasMultiChannel: boolean): string {
    if (this.canPlayMultiChannel(hasMultiChannel)) {
      url = url.replace('/playlist', '/multi-channel-playlist')
      return url.replace('/mono/', '/multichannel/')
    }
    url = url.replace('/multi-channel-playlist', '/playlist')
    return url.replace('/multichannel/', '/mono/')
  }

  canPlayMultiChannel(hasMultiChannel: boolean): boolean {
    return (
      hasMultiChannel && this.isSupportedBrowser() && this.browserFeaturesService.isMediaSourceExtensionsSupported()
    )
  }

  private isSupportedBrowser(): boolean {
    const isAndroidChromium = this.browserSnifferService.isAndroid() && this.browserSnifferService.isChromeLike()
    return !this.browserSnifferService.isSafari() && !isAndroidChromium
  }
}
