import { ApiResult, tapData, tapFailure } from '@ftr/foundation'
import { ClassConstructor } from '@ftr/serialization'
import { isErrorConflict } from './is-error-conflict'

export interface APIConflictConfiguration<TConflictResponse, TSuccessResponse = null> {
  responseConstructor: ClassConstructor<TConflictResponse>
  request: (conflictToken: string | undefined) => ApiResult
  onSuccess: (data: TSuccessResponse) => void
  onError: (error: Error) => void
  onCancel?: () => void
  onConflict: (responseBody: TConflictResponse, hasPreviousConflictResolutionTokenExpired: boolean) => Promise<boolean>
}

export function apiRequestWithConflict<T extends { conflictResolutionToken: string }>(
  config: APIConflictConfiguration<T>,
): void {
  let hasPreviousConflictResolutionTokenExpired = false
  let currentConflictToken: string | undefined = undefined

  const handleFailure = async (error: Error): Promise<void> => {
    if (isErrorConflict(error)) {
      const body = error.getConflictBody(config.responseConstructor)

      hasPreviousConflictResolutionTokenExpired =
        currentConflictToken !== undefined && currentConflictToken !== body.conflictResolutionToken

      currentConflictToken = body.conflictResolutionToken

      const conflictAccepted = await config.onConflict(body, hasPreviousConflictResolutionTokenExpired)

      conflictAccepted ? doRequest() : config.onCancel?.()
    } else {
      config.onError(error)
    }
  }

  const doRequest = (): void => {
    config.request(currentConflictToken).pipe(tapData(config.onSuccess), tapFailure(handleFailure)).subscribe()
  }

  doRequest()
}
