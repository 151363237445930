import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, EnvironmentProviders, ErrorHandler, NgModule, Provider } from '@angular/core'
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt'
import { RepositoryState } from '@ftr/api-recording-vault'
import {
  API_CONFIGURATION,
  AUTH_CONFIGURATION,
  AUTH_TOKEN_FETCHER,
  DESKTOP_CONFIGURATION,
  STANDALONE_APP_CONFIGURATION,
} from '@ftr/api-shared'
import {
  DialogComponent,
  IllustrationComponent,
  KonamiDirective,
  LayoutService,
  OnClickOutsideDirective,
  SimpleWindowRefService,
  SizesService,
  TippyComponent,
  TrackingService,
} from '@ftr/foundation'
import { CourtSystemsState } from '@ftr/ui-court-system'
import { FeatureFlagState, LAUNCH_DARKLY_CONFIG, LaunchDarklyModule } from '@ftr/ui-feature-flags'
import { CourtroomState } from '@ftr/ui-location'
import { OrderAccountState, OrderState } from '@ftr/ui-ordering'
import { AnnotationsState, RealTimeSealingState, RealTimeSessionsState, RealTimeSttState } from '@ftr/ui-rtstt'
import { RealTimeSearchState, SearchManyState, ThisRecordingSearchState } from '@ftr/ui-search'
import { AuthTokenFetcherService, AuthenticationService, UserState } from '@ftr/ui-user'
import { VocabularyState } from '@ftr/ui-vocab'
import '@js-joda/timezone-10-year-range'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsModule } from '@ngxs/store'
import { InlineSVGModule } from 'ng-inline-svg'
import { DesktopModule } from '~app/features/desktop/desktop.module'
import { HeaderUnderlayModule } from '~app/features/header-underlay/header-underlay.module'
import { JobServiceModule } from '~app/services/job-service/job-service.module'
import { LaunchDarklyAttributeService } from '~app/services/launch-darkly-attribute/launch-darkly-attribute.service'
import { WindowRefService } from '~app/services/window/window-ref.service'
import { NGXS_OPTIONS } from '~store/ngxs-options'
/*
 * Platform and Environment providers/directives/pipes
 */
import {
  BreadcrumbModule,
  ConfirmationModalComponent,
  PageComponent,
  PortalComponent,
  ToastModule,
} from '@ftr/foundation'
import { ComplexFilterState } from '@ftr/lists'
import { CasesState, HearingsState } from '@ftr/ui-annotations'
import { FooterComponent, KeyPressState, MfaRequirementsState } from '@ftr/ui-core'
import { ErrorHandlerService, OBSERVABILITY_CONFIGURATION } from '@ftr/ui-observability'
import { PlaybackState, RecordingsFilterState, SharedRecordingState } from '@ftr/ui-playback'
import { RecordingTimelineState } from '@ftr/ui-recording-timeline'
import { UpcomingHearingsListState } from '@ftr/ui-upcoming-hearings-list'
import { FavoritesState, SimpleTrackingService, UseConditionsState } from '@ftr/ui-user'
import { AppDisplayComponent } from '~app/core/app-display/app-display.component'
import { AppLoaderComponent } from '~app/core/app-loader/app-loader.component'
import { PostLoginNotificationComponent } from '~app/features/audit-log/post-login-notification/post-login-notification.component'
import { MfaSetupModalComponent } from '~app/features/multifactor-authentication/setup-modal/mfa-setup-modal.component'
import { AppLayoutService } from '~app/services/layout/app-layout.service'
import { RecordKeyPressToStoreService } from '~app/services/record-key-press-to-store/record-key-press-to-store.service'
import { environment } from '~environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CoreModule } from './core/core.module'
import { HeaderModule } from './core/header/header.module'
import { OrderSharedModule } from './order-shared/order-shared.module'
import { ConfigurationService } from './services/configuration/configuration.service'
import { HeaderSizeService } from './services/header-size/header-size.service'
import { AuthRedirectInterceptor } from './services/http-client/auth-redirect.interceptor'
import { jwtOptionsFactory } from './services/http-client/jwt-options.factory'
import { LoggingInterceptor } from './services/http-client/logging.interceptor'

// Application wide providers
const APP_PROVIDERS: (Provider | EnvironmentProviders)[] = [
  { provide: ErrorHandler, useClass: ErrorHandlerService },
  { provide: TrackingService, useClass: SimpleTrackingService },
  { provide: SimpleWindowRefService, useClass: WindowRefService },
  { provide: SizesService, useClass: HeaderSizeService },
  { provide: LayoutService, useClass: AppLayoutService },
  { provide: LAUNCH_DARKLY_CONFIG, useClass: ConfigurationService },
  { provide: API_CONFIGURATION, useClass: ConfigurationService },
  { provide: AUTH_CONFIGURATION, useClass: ConfigurationService },
  { provide: DESKTOP_CONFIGURATION, useClass: ConfigurationService },
  { provide: STANDALONE_APP_CONFIGURATION, useClass: ConfigurationService },
  { provide: OBSERVABILITY_CONFIGURATION, useClass: ConfigurationService },
  { provide: AUTH_TOKEN_FETCHER, useClass: AuthTokenFetcherService },
  // Wire up launch darkly to apply attributes globally from the store
  {
    provide: APP_INITIALIZER,
    useFactory: (service: LaunchDarklyAttributeService) => () => service.observeAndApplyAttributes(),
    deps: [LaunchDarklyAttributeService, LAUNCH_DARKLY_CONFIG],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: (service: RecordKeyPressToStoreService) => () => service.syncKeyPressesToStore(),
    deps: [RecordKeyPressToStoreService],
    multi: true,
  },
]

const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthRedirectInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
]

/**
 * `AppModule` is the main entry point into Angular's bootstrap process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    CommonModule,
    NgxsModule.forRoot(
      [
        UserState,
        ThisRecordingSearchState,
        SearchManyState,
        PlaybackState,
        CourtSystemsState,
        VocabularyState,
        RepositoryState,
        OrderState,
        OrderAccountState,
        CourtroomState,
        FeatureFlagState,
        RealTimeSttState,
        RealTimeSearchState,
        UseConditionsState,
        RecordingsFilterState,
        MfaRequirementsState,
        SharedRecordingState,
        AnnotationsState,
        RealTimeSessionsState,
        RealTimeSealingState,
        KeyPressState,
        CasesState,
        HearingsState,
        RecordingTimelineState,
        FavoritesState,
        ComplexFilterState,
        UpcomingHearingsListState,
      ],
      NGXS_OPTIONS,
    ),
    AppDisplayComponent,
    AppLoaderComponent,
    HeaderModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthenticationService, ConfigurationService],
      },
    }),
    InlineSVGModule.forRoot(),
    CoreModule.forRoot(),
    OrderSharedModule.forRoot(),
    JobServiceModule.forRoot(),
    AppRoutingModule,
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
      name: 'FTR-WEB',

      // These are super noisy and polling, essentially making the devtools unusable
      actionsBlacklist: [
        '\\[Annotations\\] Set Live LocalTime',
        '\\[Playback\\] Reevaluate on record state',
        '\\[RealTimeStt\\] Set RealTime Stt Hearing Markers',
        '\\[RealTimeStt\\] Set RealTime Stt Session Markers',
        '\\[Recording Timeline\\] Update Hearing Timeline Sections',
        '\\[Recording Timeline\\] Update Session Timeline Sections',
      ],
    }),
    BreadcrumbModule,
    ToastModule,
    DesktopModule,
    HeaderUnderlayModule,
    MfaSetupModalComponent,
    PostLoginNotificationComponent,
    PortalComponent,
    PageComponent,
    LaunchDarklyModule.forRoot(),
    DialogComponent,
    ConfirmationModalComponent,
    FooterComponent,
    KonamiDirective,
    IllustrationComponent,
    TippyComponent,
    OnClickOutsideDirective,
  ],
  providers: [APP_PROVIDERS, httpInterceptorProviders],
})
export class AppModule {}
