import { Injectable } from '@angular/core'
import { getPublicRecordingSocketRoomId } from '@ftr/api-core'
import { ApiClient, ApiClientFactory, isNotFoundError, serializeHttpParams } from '@ftr/api-shared'
import { OptionalCourtSystemIdQuery } from '@ftr/contracts/api/shared'
import { SealingRequest, TrmConversionFinalized } from '@ftr/contracts/message/recording'
import { AssetStatus, RecordingWithSegments } from '@ftr/contracts/read'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { TrmConversionStatus } from '@ftr/contracts/type/recording'
import { Timecode, Uuid } from '@ftr/contracts/type/shared'
import {
  ApiResult,
  ErrorPageService,
  createAndAssign,
  hasNonNullOrUndefinedProperties,
  tapFailure,
} from '@ftr/foundation'
import { Observable, filter, map } from 'rxjs'
import { SocketService } from '../socket'

/**
 * Represents a new, converted, segment added to a recording
 */
export interface RecordingSegmentConversionUpdate {
  /**
   * ID of the segment
   */
  id: Uuid

  /**
   * Timecode of the new segment
   */
  timecode: Timecode

  /**
   * The status of the asset
   */
  assetStatus: AssetStatus
}

@Injectable({
  providedIn: 'root',
})
export class CourtRecordingService {
  private readonly apiClient: ApiClient

  constructor(
    apiClientFactory: ApiClientFactory,
    private readonly socketService: SocketService,
    private readonly errorPageService: ErrorPageService,
  ) {
    this.apiClient = apiClientFactory.build('/court-recording')
  }

  /**
   * Returns the recording with segments for playback.
   * @param recordingId The recording ID.
   * @param courtSystemId The court system ID, which is only required for regional recordings.
   */
  getWithSegments(recordingId: Uuid, courtSystemId?: Uuid): ApiResult<RecordingWithSegments> {
    return this.apiClient
      .get({
        path: `${recordingId}/withSegments`,
        params: serializeHttpParams(createAndAssign(OptionalCourtSystemIdQuery, { courtSystemId })),
        responseBodyType: RecordingWithSegments,
      })
      .pipe(
        tapFailure(err => {
          if (isNotFoundError(err)) {
            this.errorPageService.showForbiddenPageWithMessage('Recording not found')
          }
        }),
      )
  }

  listenToSuccessfulTrmConversions(recordingId: Uuid): Observable<RecordingSegmentConversionUpdate> {
    return this.socketService.onMessage(getPublicRecordingSocketRoomId(recordingId), TrmConversionFinalized).pipe(
      filter(hasNonNullOrUndefinedProperties('timecode')),
      filter(message => message.recordingTrm.recordingId === recordingId),
      filter(message => message.trmConversionStatus === TrmConversionStatus.Complete),
      map(message => ({
        id: message.recordingTrm.trmId,
        timecode: message.timecode,
        assetStatus: mapTrmConversionStatusToAssetStatus(message.trmConversionStatus),
      })),
    )
  }

  listenToTrmConversionUpdates(recordingId: Uuid): Observable<RecordingSegmentConversionUpdate> {
    return this.socketService.onMessage(getPublicRecordingSocketRoomId(recordingId), TrmConversionFinalized).pipe(
      filter(hasNonNullOrUndefinedProperties('timecode')),
      filter(message => message.recordingTrm.recordingId === recordingId),
      map(message => ({
        id: message.recordingTrm.trmId,
        timecode: message.timecode,
        assetStatus: mapTrmConversionStatusToAssetStatus(message.trmConversionStatus),
      })),
    )
  }

  unarchive(recordingId: Uuid): ApiResult {
    return this.apiClient.post({
      path: recordingId + '/unarchive',
    })
  }

  seal(recordingId: Uuid, start: number, end: number): ApiResult {
    const body = new SealingRequest(recordingId, true, start, end)
    return this.apiClient.post({
      path: '/seal',
      body,
    })
  }

  reconvert(recordingId: Uuid): ApiResult {
    return this.apiClient.post({
      path: `${recordingId}/convert`,
    })
  }

  archive(recordingId: Uuid): ApiResult {
    return this.apiClient.post({
      path: `${recordingId}/archive`,
    })
  }

  unseal(recordingId: Uuid, start: number, end: number): ApiResult {
    const body = new SealingRequest(recordingId, false, start, end)
    return this.apiClient.post({
      path: '/seal',
      body,
    })
  }
}

function mapTrmConversionStatusToAssetStatus(x: TrmConversionStatus): AssetStatus {
  switch (x) {
    case TrmConversionStatus.Complete:
      return AssetStatus.Exists
    case TrmConversionStatus.Failed:
      return AssetStatus.Broken
    case TrmConversionStatus.InProgress:
      return AssetStatus.Generating
    case TrmConversionStatus.NotStarted:
      return AssetStatus.Missing
    default:
      assertUnreachable(x)
  }
}
